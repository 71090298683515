@import '../../assets/styles/utils';

:global(.ReactModal__Body--open) {
	overflow: hidden;
}
:global(.ReactModal__Body--open),
:global(.ReactModal__Html--open) {
	overflow: hidden;
}

:global(.ReactModal__Overlay) {
	overflow-y: auto;
	overflow-x: hidden;
	opacity: 0;
	transition: opacity 100ms ease-in-out;
}
:global(.ReactModal__Content){
	//transform: scale(.95);
	//transition: transform 200ms ease-in-out;
}

:global(.ReactModal__Overlay--after-open){
	opacity: 1;
}

:global(.ReactModal__Overlay--before-close){
	opacity: 0;
}
:global(.ReactModal__Overlay--after-open .ReactModal__Content){
	//transform:scale(1);
}
:global(.ReactModal__Overlay:before) {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}


.title{
	font-weight: 500;
	font-size: 24px;
	color: #392200;
	@include padding(16px, 50px, 24px, 20px);

	a{
		color: var(--color-link);
		text-decoration: none;
		transition: color $transition;

		&:hover{
			color: var(--color-dark);
		}
	}
}

.close{
	position: absolute;
	width: 45px;
	height: 45px;
	cursor: pointer;
	top: 4px;
	box-sizing: border-box;
	@include right(2px);

	svg{
		width: 100%;
		height: 100%;
		fill: var(--color-grey);
	}

	&:hover{
		svg{
			fill:#000;
		}
	}
}

.content{
	padding: 0 20px 20px;
}

.text{
	font-weight: 400;
	font-size: 16px;
	line-height: 1.6;
	color:#000;
	margin-bottom:24px;

	p{
		padding: 0;
		margin: 0 0 8px 0;
	}

	a{
		color: var(--color-link);
	}
}

.subtitle{
	font-weight: 600;
	font-size: 16px;
	line-height: 1.5;
	color:#000;
	margin-top:24px;
	margin-bottom:8px;
}

.list{

}

.item{
	font-size: 16px;
	display:flex;
	flex-wrap: nowrap;
	width: 100%;
	padding: 4px 0;
	margin-bottom: 4px;

	span{
		flex:1;
	}

	a{
		color: var(--color-link);
	}
}

.number{
	@include margin-right(5px);
}

.code{
	border: 2px solid var(--color-input);
	border-radius: 4px;
	padding: 0 12px;
	height: 40px;
	line-height: 36px;
	display: flex;
	flex-wrap: nowrap;
	width: min-content;
	margin:24px 0 0 0;
}

.codeText{
	font-weight: 500;
	font-size: 18px;
	color: #000000;
}

.codeCopy{
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	color: var(--color-link);
	cursor: pointer;
	display: flex;
	align-items: center;
	@include margin-left(25px);

	svg{
		fill: var(--color-link);
	}

	span{
		width: 25px;
		height: 25px;
	}
}

.getCode{
	color: var(--color-grey);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.43;
	margin:12px 0 0 0;
	cursor:pointer;

	&:hover{
		color:  var(--color-link);
	}
}

.annotate{
	display: inline;
	color:  var(--color-grey);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.43;
	margin:12px 0 0 0;
}

.annotateBold{
	font-weight: 500;
}

.telegram{
	position:relative;
	min-height: 92px;
}

.demo{
	@include min1180{
		display:flex;
		width:100%;
		justify-content: space-between;
	}
}