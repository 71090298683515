@import "../../../assets/styles/utils";

.sizer{
	display:flex;
	flex-wrap: nowrap;
}

.input{
	height:32px;
	line-height: 32px;
	padding: 0 8px;
	border: 1px solid var(--color-input);
	border-radius: 4px;
	display: inline-block;
	margin:0 6px;
	font-weight: 400;
	font-size: 14px;
	min-width:52px;
	text-align: center;
	color: var(--color-grey);
}

.button{
	width: 25px;
	height: 25px;
}