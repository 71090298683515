@import "../../../../../assets/styles/utils";

.background{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height: 100%;
	cursor:pointer;
	background-color:rgba(0, 0, 0, 0.3);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	&.playing{
		background: none;
		opacity: 0;
	}
}

.play{
	position:absolute;
	left: 50%;
	top: 50%;
	background: #FFFFFF;
	box-shadow: -13px 15px 20px 2px rgba(68, 71, 77, 0.1);
	border-radius: 50%;
	width:40px;
	height:40px;
	margin-top:-20px;
	margin-left: -20px;

	svg{
		fill:#000!important;
	}
}