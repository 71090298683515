@import "../../../assets/styles/utils";


.form{
	padding: 12px 0;

	@include min1180{
		padding: 20px 0 0;
	}

	&.inner{
		@include min1180{
			padding: 20px 20px 0;
		}
	}
}

.row{
	margin-bottom: 24px;

	& > label{
		margin: 0;
	}

	&.subList{
		@include padding-left(25px);
	}

	&.miniMargin{
		margin-bottom: 16px;
	}

	&.noMargin{
		margin-bottom: 0;
	}

	&.noGap{
		@include min1180{
			gap:0;
		}
	}

	@include min1180{
		display: flex;
		width: 100%;
		gap: 20px 30px;
		flex-wrap: wrap;
	}

	&.align-end{
		align-items: flex-end;
	}

	&.align-center{
		align-items: center;
	}

	&.justify-end{
		justify-content: flex-end;
	}

	&.justify-between{
		justify-content: space-between;
	}

	& > .title{
		&.h2,
		&.h6 {
			@include min1180 {
				margin: 0;
			}
		}
	}
}

.cell{
	box-sizing: border-box;
	margin: 0 0 20px 0;

	& > label{
		margin: 0;
	}

	@include min1180{
		margin: 0;
	}

	&.w25{
		@include min1180 {
			width: calc(25% - 25px);
		}
	}

	&.w33{
		@include min1180 {
			width: calc(33.3333% - 15px);
		}
	}

	&.w50{
		@include min1180 {
			width: calc(50% - 15px);
		}
	}

	&.w75{
		@include min1180 {
			width: calc(74% - 15px);
		}
	}

	&.auto{
		flex: initial;
	}

	&.full{
		flex: 1;
	}
}

.title{
	font-weight: 500;
	font-size: 16px;
	line-height: 1.5;
	color: #000;
	margin-bottom: 8px;


	&.h1{
		font-size: 18px;
		line-height: 1.44;
		margin-bottom: 12px;
	}

	&.h6{
		margin-bottom: 2px;
		font-size: 12px;
		color: var(--color-grey);
		font-weight: 400;
	}

	&.noMargin{
		margin: 0;
	}


	&.white{
		color: #fff;
	}
}

.dl{
	display:flex;
	width:100%;
	padding: 0 0 16px 0;
	margin-bottom: 16px;
	border-bottom:1px solid #D3D3D3;
	overflow: hidden;
}

.dt{
	font-weight: 500;
	font-size: 18px;
	line-height: 1.44;
	color: #000000;
	flex:1;
}

.dd{
	label{
		margin: 7px 0 0 0px;
	}
}


.devide{
	border-bottom: 2px solid #D3D3D3;
	margin-bottom: 16px;

	&.blue{
		border-color: var(--color-blue);
	}
}