@import "../../../assets/styles/utils";

.title{
	font-weight: 500;
	font-size: 40px;
	line-height: 1.2;
	color: #000000;
	padding:0;
	margin: 0;
	margin-bottom: 14px;
}