@import "../../../assets/styles/utils";

.pagination{
	@include min1180{
		display:flex;
		width:100%;
		justify-content: space-between;
	}
}

.controls{
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	margin: 0 0 16px 0;

	@include min1180{
		margin:0;
	}
}

.limit{
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.text{
	font-weight: 400;
	font-size: 14px;
	color: #000000;
}

.field{
	width: 75px;
	margin: 0 16px;

	label{
		margin:0;
	}
}

.button{
	background: var(--color-light);
	border-radius: 4px;
	height:32px;
	width: 32px;
	display: inline-block;
	cursor:pointer;
	padding: 8px;
	transition: background .2s;
	vertical-align: middle;
	@include margin-right(6px);

	&:hover{
		background:var(--color-link);

		svg{
			fill: #fff;
		}
	}
}

.link{
	border-radius: 4px;
	height:32px;
	line-height: 32px;
	width: 32px;
	display: inline-block;
	text-align: center;
	transition: color .2s;
	color: #656565;
	font-size: 14px;
	vertical-align: middle;
	@include margin-right(6px);


	&.pointer{
		cursor:pointer;
		&:hover{
			color: var(--color-link);
		}
	}

	&.active{
		color:var(--color-link);
	}
}

.header{
	position:relative;
	background: #fff;
}

.arrow{
	position: absolute;
	bottom: 10px;
	width: 20px;
	height: 20px;
	@include right(10px);
}