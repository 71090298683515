@import "../../../assets/styles/utils";

.header{
	position: relative;
	display: flex;
	width: 100%;
}

.arrow{
	position: absolute;
	background: none;
	border: none;
	outline: none;
	width: 20px;
	height: 20px;
	top: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;

	svg{
		fill: var(--color-grey);
	}

	&:hover{
		svg{
			fill: #000;
		}
	}

	&.arrowLeft{
		@include left(0);

		html[dir="rtl"] &{
			transform: rotate(180deg);
		}
	}

	&.arrowRight{
		@include right(0);

		html[dir="rtl"] &{
			transform: rotate(180deg);
		}
	}
}

.column{
	flex:1;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
}

.month{
	font-weight: 700;
	font-size: 14px;
	color: #000;
}

.year{
	font-weight: 500;
	font-size: 14px;
	color: #B8B8B8;
}

.yearIcon{
	position: relative;
	display: inline-block;
	width: 16px;
	vertical-align: middle;

	svg{
		position: absolute;
		height: 20px;
		width: 16px;
		top: -3px;
		left: 0;
		fill:#B8B8B8;
	}
}

:global{
	.datepicker {
		.rmdp-wrapper {

		}

		.rmdp-shadow {
			box-shadow: none;
		}

		.rmdp-day,
		.rmdp-week-day {
			width: 38px;
			height: 38px;
		}

		.rmdp-week-day {
			color: #B8B8B8;
			font-weight: 400;
			font-size: 14px;
		}

		.rmdp-day-picker {
			& > div + div {
				@include margin-left(24px);
			}
		}

		.rmdp-week {
			margin-bottom: 1px;
		}

		.rmdp-day {
			color: #000;
			font-size: 14px;
			font-weight: 400;
			border-top: 1px solid transparent;
			border-bottom: 1px solid transparent;
			overflow: hidden;

			span {
				left: 1px;
				right: 1px;
				top: 1px;
				bottom: 1px;
				border-radius: 3px;
			}

			&.rmdp-today {
				span {
					background-color: transparent;
					color: #000;
				}
			}

			&:not(.rmdp-disabled):not(.rmdp-day-hidden) {
				&:hover {
					background: var(--color-blue);
					color: #fff;

					span {
						background: transparent;
					}
				}
			}

			&:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-range) {

				&:hover {
					border-radius: 4px;
				}
			}

			&.rmdp-range {
				background-color: var(--color-blue);
				box-shadow: none;
				color: #fff;

				span {
					color: #fff !important;
					background-color: var(--color-blue) !important;
				}
			}

			&.rmdp-range-hover {
				background: transparent !important;
				border-top-color: var(--color-blue);
				border-bottom-color: var(--color-blue);

				span {
					border-radius: 0;
					color: #000 !important;
				}

				&.end,
				&.start {
					background-color: var(--color-blue);
				}
			}

			&.rmdp-range,
			&.rmdp-range-hover {
				&.start {
					border-bottom-left-radius: 3px;
					border-top-left-radius: 3px;
					border-left: 1px solid var(--color-blue);
					background-color: var(--color-blue) !important;

					span {
						color: #fff !important;
					}
				}

				&.end {
					border-bottom-right-radius: 3px;
					border-top-right-radius: 3px;
					border-right: 1px solid var(--color-blue);
					background-color: var(--color-blue) !important;

					span {
						color: #fff !important;
					}
				}

				&.start.end {
					border-radius: 3px;
				}

			}

			.sd {
				&.day-0 {
					background-color: var(--color-day-0);
				}
				&.day-250 {
					background-color: var(--color-day-250);
				}
				&.day-350 {
					background-color: var(--color-day-350);
				}
				&.day-500 {
					background-color: var(--color-day-500);
				}
				&.day-1000 {
					background-color: var(--color-day-1000);
				}
			}
		}

		.rmdp-rtl {
			.rmdp-range-hover,
			.rmdp-range {
				&.start {
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
					border-bottom-right-radius: 3px;
					border-top-right-radius: 3px;
				}

				&.end {
					border-bottom-left-radius: 3px;
					border-top-left-radius: 3px;
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}

				&.start.end {
					border-radius: 3px;
				}
			}
		}
	}
}