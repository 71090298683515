@import "../../assets/styles/utils";


.faq{
	display: grid;
	grid-template-rows: min-content 1fr;
	height: 100%;
	background: #fff;

	@include min1180{
		display:block;
		background: none;
	}
}

.content{
	position:relative;
	padding: 24px 12px;

	@include min1180{
		box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
		background: #fff;
		border-radius: 4px;
		padding: 24px 32px;
		min-height: calc(100vh - 126px);
	}
}

.back{
	margin: 0 0 20px 0;

	@include min1180{
		position: absolute;
		top:-5px;
		@include left(-5px);
	}
}

.header{

}

.breadcrumbs{
	margin: 0 0 16px 0;
	display:flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 20px;
}

.breadcrumbsLink{
	position: relative;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.14;
	color: var(--color-grey);
	cursor:pointer;

	&:hover{
		color: var(--color-link);
	}
}

.breadcrumbsItem{
	position: relative;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.14;
	color: var(--color-grey);
}

a.breadcrumbsItem{
	cursor:pointer;

	&:hover{
		color: var(--color-link);
	}
}

.breadcrumbsIcon{
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	right: -18px;
	top: 0;
}

.info{
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-bottom:16px;
}

.size{
	display:flex;
	flex-wrap: nowrap;
	align-items: flex-end;
}

.smallIcon{
	width:12px;
	height:13px;
	margin-bottom:1px;
	cursor:pointer;

	svg{
		fill: var(--color-grey);
		opacity:.4;
		transition: fill $transition;
	}

	&:hover{
		svg{
			fill:#000;
		}
	}
}

.largeIcon{
	width:16px;
	height:18px;
	cursor:pointer;
	@include margin-left(8px);

	svg{
		fill: var(--color-grey);
		transition: fill $transition;
	}

	&:hover{
		svg{
			fill:#000;
		}
	}
}

.title{
	font-weight: 500;
	font-size: 32px;
	line-height: 1.25;
	color: #000000;
}

.download{
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.downloadLink{
	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
	color: var(--color-link);
	cursor: pointer;
}

.downloadIcon{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	@include margin-left(5px);

	svg{
		//position: absolute;
		//top: -8px;
		//left: -8px;
		//width: 16px;
		//height: 16px;
		fill: var(--color-link)!important;
	}
}

.body{
	padding: 12px 0 0 0;
	font-weight: 400;
	line-height: 1.6;
	color: #656565;

	div{
		font-size: inherit;
	}

	section{
		font-size: inherit;
		margin: 0 0 32px 0;
	}

	p{
		margin: 0 0 24px 0;
		padding: 0;
		max-width: 600px;
	}

	h3{
		font-weight: 400;
		font-size: 145%;
		line-height: 1.33;
		color: #000000;
		padding: 0;
		margin: 0 0 16px 0;
		max-width: 600px;
	}

	h4{
		font-weight: 500;
		font-size: 100%;
		line-height: 1.5;
		color: #000000;
		padding:0;
		margin: 0 0 12px 0;
		max-width: 600px;
	}

	ol{
		max-width: 600px;
		margin:0;
		@include padding(0, 0, 0, 15px);
	}

	ul{
		max-width: 600px;
		margin:0;
		@include padding(0, 0, 0, 35px);
	}

	li{
		padding: 0;
		margin: 0 0 8px 0;
	}

	img{
		max-width: 600px;
		display: block;
		width: 100%;
		height: auto;
		margin: 0 0 24px 0;
	}

	table{
		margin: 0 0 32px 0;
		table-layout: fixed;
		width: 100%;
		border-collapse: collapse;

		p{
			margin: 0 0 16px 0;
		}

		th{
			vertical-align: bottom;
			text-align:left;
			font-weight: 500;
			font-size: 90%;
			line-height: 1.43;
			color: #656565;
			padding:8px;
			border-bottom: 1px solid #d3d3d3;
			margin:0;
		}

		th:first-child{
			@include padding(8px, 8px, 8px, 0);
		}

		th:last-child{
			@include padding(8px, 0, 8px, 8px);
		}

		td:nth-child(1),
		th:nth-child(1){
			width:15%;
		}

		td:nth-child(2),
		th:nth-child(2){
			width:35%;
		}

		td:nth-child(3),
		th:nth-child(3){
			width:50%;
		}

		td{
			vertical-align: top;
			text-align:left;
			font-weight: 400;
			font-size: 90%;
			line-height: 1.7;
			color: #656565;
			padding:19px 8px;
			border-bottom: 1px solid #d3d3d3;
		}

		td:first-child{
			@include padding(19px, 8px, 19px,0);
		}

		td:last-child{
			@include padding(19px, 0, 19px, 8px);
		}
	}
}

.navigation{
	margin: 40px 0 0 0;
	display:flex;
	width: 100%;
	justify-content: space-between;


	html[dir="rtl"] & {
		justify-content: flex-end;
	}
}

.navigationButton{
	margin: 0 0 20px 0;

	@include text-align('left');

	&.right{
		@include text-align('right');
	}

	@include min1180{
		margin:0;
	}
}

.navigationTitle{
	font-weight: 400;
	font-size: 14px;
	line-height: 1.33;
	color: #000000;
	margin: 0 0 12px 0;

	@include min1180{
		font-size: 24px;
	}
}

.navigationLink{
	font-weight: 400;
	font-size: 14px;
	line-height: 1.5;
	color: var(--color-link);

	@include min1180{
		font-size: 16px;
	}
}

.structure{
	flex: 1;
	height: 100%;
	overflow: auto;

	@include min1180{
		@include padding(12px, 0, 12px, 6px);
	}
}

.structureTitle{
	position: sticky;
	top:0;
	z-index:122;
}

.structureContent{
	margin: 8px 0 0 0;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	box-sizing: border-box;
	padding: 20px;
}

.structureList{

}

.structureItem{
	margin: 12px 0 0 0;

	&:first-child{
		margin:0;
	}
}

.structureLink{
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	color: #323232;
	cursor:pointer;

	&:hover{
		color: var(--color-link);
	}
}

.structureSub{
	font-weight: 400;
	font-size: 14px;
	line-height: 1.7;
	color: #323232;
	cursor:pointer;
	@include margin(6px, 0, 0, 12px);

	&:hover{
		color: var(--color-link);
	}
}

.intro{

}

.introLink{
	display: block;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.44;
	color: #000000;
	margin:0 0 12px 0;

	&:hover{
		color: var(--color-link);
	}
}