@import "../../../assets/styles/utils";

.bar{
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	width:100%;
	display:flex;
	flex-wrap: nowrap;
	padding: 5px 12px;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4px;
}

.info{
	display:flex;
	flex-wrap: nowrap;
}

.date{
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #000000;
}

.toggle{
	font-weight: 400;
	font-size: 12px;
	line-height: 1;
	color: #000000;
	@include margin-left(8px);
	text-transform: uppercase;
	cursor:pointer;
}

.icon{
	width: 10px;
	height: 10px;
	position:relative;
	display:inline-block;
	margin-left: 5px;

	span{
		position:absolute;
		width:16px;
		height:16px;
		top: -2px;
		@include left(-2px);
	}
}

.checkbox{
	label{
		margin:0;
	}
}