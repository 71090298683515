@import "../../assets/styles/utils";

.container{
	display: inline-block;
	vertical-align: baseline;
	position: relative;
}

.header{
	//cursor: pointer;
}

.tooltip{
	position: fixed;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	bottom: 100%;
	//right: 0;
	z-index: 10000001;
	padding: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.14;
	height: max-content;
	width: max-content;
	color: var(--color-grey);
	transition: opacity .3s, transform .2s;
	transform: translateY(3px);
	opacity: 0;
	margin: 8px 0;
	will-change: transform;

	&.active {
		transform: translateY(0);
		opacity: 1;
	}

	p{
		margin: 0;
	}

	p + p{
		margin-top: 6px;
	}

	b{
		color: #000;
		font-weight: 500;
	}

	a{
		color: var(--color-link);
		cursor: pointer;

		&:hover{
			color: var(--color-dark);
		}
	}

	 display: none;

	 &:last-child{
		 display: block;
	 }

	&.blue{
		background: var(--color-light);
		padding: 8px 12px 12px;
	}
}

.tooltipIcon{
	cursor: pointer;
	display: flex;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin: 0 8px;
	background-color: var(--color-link);
	position: relative;
	top: 2px;

	span,
	svg{
		display: block;
	}

	svg{
		fill: #fff;
	}

	&.input,
	&.blue{
		top: 3px;
		margin: 0 5px;
		width: 14px;
		height: 14px;
	}

	&.filter{
		background-color: var(--color-input);

		svg{
			fill: var(--color-dark);
		}
	}

	&.light{
		top: 3px;
		margin: 0 5px;
		width: 14px;
		height: 14px;
		background-color: var(--color-input);

		svg{
			fill: var(--color-dark);
		}
	}
}

.disabled{
	margin-top: 6px;
	display: inline-block;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.17;
	color: var(--color-link);
	transition: color $transition;
	cursor: pointer;

	&:hover{
		color: var(--color-dark);
	}
}