@import "../../assets/styles/utils";


.sortable{
	position: relative;
	margin-bottom:16px;
}

.item{
	position: relative;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 4px;
	display: flex;
	justify-content: space-between;
	transition: padding $transition, background $transition;
	align-items: center;
	@include padding(8px, 12px, 8px, 36px);

	@include min1180{
		@include padding(8px, 12px, 8px, 12px);

		&:hover{
			@include padding(8px, 12px, 8px, 36px);

			.drag{
				opacity: 1;
			}
		}
	}

	&.active{
		background: var(--color-blue2);
	}

	&.focus{
		@include padding(8px, 12px, 8px, 36px);

		.drag{
			opacity: 1;
		}

		.input{
			border-color: var(--color-input);
		}
		
		.accept{
			display: block;
		}

		.text,
		.input{
			min-width: 100px;
			@include padding(0, 40px, 0, 12px);
		}
	}

	&.change{
		.accept{
			opacity: 1;
		}
	}
}

.content{
	display: flex;
	align-items: center;
	position:relative;
	flex:1;
	overflow: hidden;
	gap:12px;
	@include padding(0, 10px, 0, 0);
}

.wrapper{
	position: relative;
	overflow: hidden;
}

.text{
	display: block;
	position: relative;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	line-height: 28px;
	color: transparent;
}

.input{
	position: absolute;
	top: 0;
	@include left(0);
	background: none;
	border-radius: 4px;
	transition: border $transition;
	outline: none;
	width: 100%;
	color: #000;
	line-height: 28px;
}

.text,
.input{
	height: 28px;
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	border:1px solid transparent;
	box-sizing: border-box;
	@include padding(0, 0, 0, 12px);
}

.telegram{
	width: 12px;
	height: 12px;
	@include margin(-5px, 0, 0, -9px);

	svg{
		fill:#ACB4CD;
	}
}

.accept{
	display: none;
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	top: 6px;
	@include right(10px);
	background: var(--color-link);
	opacity: 0.5;
	padding: 1px;
	cursor: pointer;

	svg{
		fill: #fff;
	}
}

.count{
	display: block;
	height: 16px;
	line-height: 16px;
	padding: 0 6px;
	background:var(--color-light);
	border-radius: 30px;
	font-size: 12px;
	color: var(--color-grey);
	margin: 1px 0 0 0;
}

.right{
	position:relative;
	display:flex;
	justify-content: space-between;
	align-items: center;
}

.menu{
	width:24px;
	height:24px;
}

.drag{
	position: absolute;
	width: 16px;
	height: 16px;
	cursor: grabbing;
	opacity: 1;
	top:14px;
	transition: opacity $transition;
	@include left(12px);

	@include min1180{
		opacity: 0;
	}
}

.checkbox{
	@include margin-left(8px);

	label{
		margin:0;
	}
}