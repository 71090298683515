@import "../../../assets/styles/utils";

.label {
	flex: 1;
	display: flex;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 16px;
	cursor: pointer;
	line-height: 1.15;

	&:hover {
		.title{
			color:#000;
		}
	}

	&.checked{
		.icon{
			background-color: var(--color-link);
			border-color: var(--color-link);
		}
	}

	&.disabled{
		cursor: inherit;
	}

	&.start{
		flex-direction: row-reverse;

		.title{
			@include padding(0, 6px, 0, 0);
		}
	}

	&.sm{
		.title{
			font-size:12px;
		}

		.icon{
			width:14px;
			height:14px;
		}
	}


	&.light{
		&:hover {
			.title{
				color: #fff;
			}
		}

		.title{
			color: #ACB4CD;
		}

		.icon{
			border-color: #CCDFF3;
		}

		&.checked{
			.icon{
				background-color: var(--color-light2);

				svg{
					fill: var(--color-main);
				}
			}
		}
	}

	&.blue{
		&:hover {
			.title{
				color:var(--color-link);
			}
		}

		.title{
			color: var(--color-grey);
		}

		.icon{
			border-color: var(--color-link);
		}

		&.checked{
			.title{
				color:var(--color-link);
			}

			.icon{
				background-color: var(--color-link);

				svg{
					fill:#fff;
				}
			}
		}

		&.disabled{
			&:hover{
				color: var(--color-grey);
			}

			.title{
				color: var(--color-grey);
			}

			.icon{
				border-color: var(--color-grey);
			}

			&.checked{
				.icon{
					background-color: var(--color-grey);
				}
			}
		}
	}
}

.title{
	font-weight: 400;
	color: var(--color-grey);
	flex: 1;
	font-size:inherit;
	@include padding(0, 0, 0, 6px);

	a {
		color:#000;
	}
}

.input{
	display:none;
	position:absolute;
}

.icon{
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	border: 2px solid var(--color-input);
	border-radius: 2px;
	box-sizing: border-box;
	transition: background-color $transition;

	span{
		position: absolute;
		top: -2px;
		left: -2px;
		right: -2px;
		bottom: -2px;
		fill: #fff;
		height:auto;
		width:auto;
	}

	svg{
		fill: #fff;
	}
}