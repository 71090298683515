@import "../../../assets/styles/utils";

.container{
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	background-color: var(--color-main);
	height: calc(100vh - 60px);
	overflow: auto;
	box-sizing: border-box;
	opacity: 0;
	visibility: hidden;
	transition: .3s ease transform, .3s ease opacity;

	&.isOpen{
		visibility: visible;
		opacity: 1;
	}

	@include min1180{
		height: auto;
	}
}

.content{
	max-width: 1440px;
	margin: 0 auto;
	padding: 10px 12px 20px;
	box-sizing: border-box;
	position: relative;
	z-index: 123;
	background-color: var(--color-main);
}

.inner{
	display:flex;
	width:100%;
	align-items:center;
}

.icon{
	width:26px;
	height:26px;
	margin-right:6px;
}

.button{
	display:block;
	width:100%;
	text-align:left;
}

.buttonIcon{
	display: inline-block;
	width: 20px;
	@include margin-left(-12px);
}

.favourites{
	display: flex;
	width: 100%;
	box-sizing: border-box;
	flex-wrap: wrap;
	margin:12px 0 20px;

	button{
		min-width:150px;
		background: var(--color-blue);
	}

	svg{
		fill: #fff;
	}

	span{
		width: 30px!important;
		height: 30px!important;
	}
}

.pay {
	display: flex;
	width: 100%;
	box-sizing: border-box;
	flex-wrap: wrap;

	button {
		margin: 0 0 4px 0;
	}
}

.box{
	max-width: 1440px;
	box-sizing: border-box;
	background: #fff;
	border-radius:4px;
	margin: 0 auto 4px;

	&.padding{
		padding: 20px 20px 4px;
	}
}

.group{
	margin-bottom: 25px;
	display: block;

	button{
		display: block;
		@include margin(0, 0, 4px, 0);
	}

	.favourites{
		button{
			@include margin-right(16px);
		}
	}
}

.link{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 45px;
	line-height: 45px;
	padding: 0 16px;
	border-radius: 4px;
	font-size: 18px;
	font-weight: 500;
	transition: background-color ease .1s,color ease .1s;
	box-sizing: border-box;
	border: none;
	outline: none;
	min-width: 100%;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	background-color: #fff;
	color: var(--color-grey);
	@include text-align(left);
}

.nav{
	margin: 0 0 16px 0;
	display:block;
	color: var(--color-grey);
	font-size: 14px;
	font-weight: 500;

	&:hover{
		color:#000;
	}
}

.lang{
	border-top:1px solid var(--color-blue);
	padding-top: 12px;
	margin-bottom: 24px;
}

.langItem{
	display: inline-block;
	@include margin-right(8px);
	font-weight: 400;
	font-size: 14px;
	text-transform: uppercase;
	color: #fff;
	opacity: 0.8;
	cursor: pointer;

	&.active{
		opacity: 1;
	}
}