@import "../../assets/styles/utils";

.container{
	position: relative;

	@include min1180{
		width: 0;
	}

	&.showSidebar{
		@include min1180{
			width: 262px;

			.hider {
				bottom: 8px;
				@include right(-8px);
			}
		}
	}
}

.sidebar{
	padding:12px 12px 4px;
	position: sticky;
	z-index:122;
	top:0;
	background: var(--color-background);

	@include min1180{
		padding:12px 0 12px 0;
	}
}

.toggle{
	top:12px;
	position: sticky;
	z-index:122;
	background: #FFFFFF;
	border: 1px solid #D3D3D3;
	border-radius: 4px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 400;
	font-size: 18px;
	color: #000;
	cursor: pointer;
	margin: 0 0 4px 0;
	@include padding(0, 8px, 0, 20px);

	&.isOpen{
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 2px 2px 7px rgba(0, 0, 0, 0.09);
	}
}

.toggleIcon{
	position: relative;
	width: 40px;
	height: 40px;

	svg{
		fill: var(--color-blue);
	}
}

.toggleClose{
	position: absolute;
	margin: -5px;
}

.toggleOpen{
	width: 40px;
	height: 40px;
}

.filter{

}

.box{
	border: 1px solid #D3D3D3;
	border-radius: 4px;
	padding: 12px 20px 12px;
	background: #FFFFFF;
	margin: 0 0 4px 0;
	transition: border $transition, box-shadow $transition;

	&.active{
		border-color: var(--color-blue);
		box-shadow: 0 0 8px rgba(19, 69, 174, 0.32);
	}

	&.subscribe{
		height: 52px;
		width:100%;
		display:flex;
		align-items: center;
		color: #000;
		font-size:20px;
		justify-content: space-between;
		@include padding(6px, 17px, 6px, 20px);

		&.sputnik{
			svg{
				fill: var(--color-grey);
			}
		}
	}

	&.disabled{
		.button{
			opacity: 0;
			height: 0;
		}

		.checkbox{
			label{
				margin-bottom: 8px;

				& > div:first-child{
					opacity: .5;
					width: 12px;
					height: 12px;
					border-width: 1.5px;
				}
			}
		}
	}
}

.checkbox{
	label{
		transition: margin $transition, font-size $transition;

		& > div:first-child{
			transition: opacity $transition, width $transition, height $transition, border-width $transition;
		}
	}
}

.button{
	height: 20px;
	opacity: 1;
	transition: opacity $transition, height $transition;
	& + .button{
		margin-top: 4px;
	}
}

.header{
	display:flex;
	flex-wrap: nowrap;
	padding-top:8px;
	margin-bottom: 24px;
}

.title{
	font-weight: 700;
	font-size: 14px;
	line-height: 1.3;
	color: #000000;
	flex: 1;
}

.caption{
	font-weight: 500;
	font-size: 20px;
	line-height: 1.4;
	//margin-bottom:12px;
	color:#000;

	a {
		color: #000;
		transition: color $transition;

		&:hover{
			color: var(--color-link);
		}
	}
}

.icon{
	width: 16px;
	height: 16px;
	margin-top:1px;
	cursor:pointer;
	@include margin-left(16px);

	svg{
		fill: var(--color-link);
	}
}

.text{
	position: relative;
	display:inline;
	@include padding-right(38px);
	transition: font-size $transition;
	font-size: 14px;

	&.disabled{
		font-size: 12px;
	}
}

.import{
	position: absolute;
	display: inline-block;
	@include margin-left(-42px);

	svg{
		display: inline-block;
		width: 12px;
		height: 12px;
		fill: #ACB4CD;
		transition: fill $transition;
	}

	&:hover{
		svg{
			fill: #40B3E0;
		}
	}
}

.importIcon{
	display: block;
	text-align: center;
	width: 30px;
}

.counter{
	position: absolute;
	@include margin-left(-30px);
	display: inline-block;
	background: var(--color-light);
	border-radius: 30px;
	height: 16px;
	line-height: 16px;
	padding:0 6px;
	font-size: 12px;
	color: var(--color-grey);

	&.hide{
		display: none;
	}
}

.list{
	@include padding(20px, 0, 2px, 20px);
}

.link{
	font-weight: 400;
	font-size: 14px;
	line-height: 1.14;
	display:block;
	margin-bottom: 18px;
	color: var(--color-grey);

	&:hover{
		color: var(--color-link);
	}
}

.subscribeIcon{
	display:block;
	width:40px;
	height:40px;

	svg{
		fill:var(--color-link);
	}
}


.hider{
	position: absolute;
	bottom: 8px;
	z-index: 123;
	display: none;
	width: 32px;
	height: 32px;
	padding: 8px;
	background:var(--color-link);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	cursor:pointer;
	@include right(-35px);

	@include min1180{
		display: block;
	}

	span{
		transform: rotate(0);

		html[dir="rtl"] &{
			transform: rotate(-180deg);
		}
	}

	svg{
		fill:#fff;
	}

	&.active{
		span{
			transform: rotate(-180deg);

			html[dir="rtl"] &{
				transform: rotate(0deg);
			}
		}
	}
}