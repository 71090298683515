@import "../../../assets/styles/utils";

.section{
	margin: 26px 0 32px 0;
}

.partition{
	//display: flex;
	//align-items: center;
}

.title{
	font-weight: 500;
	font-size: 16px;
	line-height: 1.44;
	color: #000000;
	margin-bottom: 12px;
}

.colors{
	display:flex;
}

.colorItem{
	position: relative;
	display:flex;
	width: 30px;
	height: 30px;
	align-items: center;
	justify-content: center;
	@include margin-right(8px);
	cursor: pointer;
	overflow: hidden;
	border: 2px solid transparent;

	&.empty{
		border-color: var(--color-input);

		&:before,
		&:after{
			content: '';
			border: 1px solid var(--color-input);
			position: absolute;
			top: -1px;
			width: 40px;
			z-index: -1;
		}

		&:before{
			transform: rotate(45deg);
			left: 0;
			transform-origin: top left;
		}

		&:after{
			transform: rotate(-45deg);
			right: 0;
			transform-origin: top right;
		}
	}
}