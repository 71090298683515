@import "../../../assets/styles/utils";

.container{
	@include min1180{
		position: relative;
	}
}

.menu{
	background: #fff;
	width:420px;
	position: absolute;
	top:0;
	transform: translateY(10px);
	opacity: 0;
	visibility: hidden;
	transition: .3s ease transform, .3s ease opacity;
	z-index:10000;
	will-change: transform;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	@include right(0);

	&.isOpen{
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
	}
}

.list{
	padding:20px 32px 16px 32px;
}

.item{
	display:block;
	margin: 0 0 16px;
	color: #000000;
	font-weight: 400;
	cursor: pointer;
	max-width:180px;

	svg{
		fill: #000;
	}

	&:hover{
		color: var(--color-link);

		svg{
			fill: var(--color-link);
		}
	}
}

.icon{
	display:inline-block;
	width:24px;
	height:24px;
	vertical-align: middle;
	margin: -3px 3px 0 0;
}

.close{
	position:absolute;
	width: 40px;
	height: 40px;
	top:10px;
	cursor: pointer;
	@include right(10px);
	z-index: 23;

	svg{
		fill: var(--color-grey);
	}

	&:hover{
		svg{
			fill: #000;
		}
	}
}

.user{
	position: relative;
	color: var(--color-link);
	@include padding(22px, 32px, 0, 52px);
}

.userIcon{
	position: absolute;
	@include left(23px);
	top:15px;
	display: inline-block;
	width: 30px;
	height: 30px;

	svg{
		fill: var(--color-link);
	}
}

.footer{
	border-top: 1px solid var(--color-input);
	padding: 7px 0 9px;
	margin: 0 32px;
	display: flex;
	justify-content: space-between;
}

.lang{
	display:flex;
	flex-wrap: nowrap;
}

.langItem{
	height: 24px;
	line-height: 24px;
	color: #ACB4CD;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	font-size: 14px;
	cursor: pointer;
	transition: color .2s;
	@include margin-left(8px);

	&:hover{
		color: #000F5A;
	}

	&.active{
		color: #000F5A;
	}
}