@import "../../assets/styles/utils";

.container{
	position: relative;
	flex: 1;

	&.hasError{
		.search{
			border-color: var(--color-red);
		}

		.toggle{
			border-color: var(--color-red);
		}

		.field{
			color: var(--color-red);
		}
	}
}

.search{
	position: relative;
	display: flex;
	flex: 1;
	border: 2px solid var(--color-border);
	height: 40px;
	line-height: 40px;
	border-radius: 4px;
}

.icon{
	width: 36px;
	padding:6px 10px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	svg{
		fill:#fff!important;
	}
}

.field{
	display: block;
	flex: 1;
	border: none;
	background: none;
	outline: none;
	height: 100%;
	width: 100%;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.02em;
	@include padding(0,40px,0,0);

	&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		color:#fff;
	}
	&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color:#fff;
		opacity:  1;
	}
	&::-moz-placeholder { /* Mozilla Firefox 19+ */
		color:#fff;
		opacity:  1;
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color:#fff;
	}
	&::-ms-input-placeholder { /* Microsoft Edge */
		color:#fff;
	}

	&::placeholder { /* Most modern browsers support this now. */
		color:#fff;
	}
}

.clean{
	position: absolute;
	width: 38px;
	height: 38px;
	cursor: pointer;
	@include right(40px);
	top:0;
	display: none;

	svg{
		fill: #fff;
	}
	
	&.active{
		@include min1180 {
			display: block;
		}
	}
}

.alert{
	position: absolute;
	@include right(40px);
	top:2px;
	display: none;
	box-sizing: border-box;

	@include min1180 {
		display: block;
	}

	svg{
		fill: var(--color-red);
	}
}

.error{
	position: absolute;
	top: 100%;
	@include left(36px);
	margin-top: 4px;
	font-size: 12px;
	color: var(--color-red);
	z-index: 12234;
}

.alertIcon{
	width: 36px;
	height: 16px;
	cursor: pointer;
	line-height: 1;
	padding: 0 10px;
}

.toggle{
	width: 40px;
	height: 100%;
	display: none;
	opacity: 0;
	align-items: center;
	justify-content: center;
	padding: 6px 10px;
	cursor: pointer;
	transition: opacity $transition;
	user-select: none;

	html[dir="rtl"] &{
		border-right: 2px solid var(--color-border);
	}

	html[dir="ltr"] &{
		border-left: 2px solid var(--color-border);
	}

	@include min1180{
		opacity: 1;
		display: flex;
	}

	svg{
		fill:#fff!important;
	}

	&.focus{
		display: flex;
		opacity: 1;
	}
}