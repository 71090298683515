@import "../../assets/styles/utils";

.container{
	background: var(--color-background);
	padding-bottom: 4px;
	z-index: 100;

	&.sticky{
		position:sticky;
		top: 0;
	}
}

.inner{
	background: var(--color-light);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	height: 48px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	@include padding(0, 16px, 0, 20px);
}

.left{
	display: flex;
	align-items: center;
}

.back{
	width: 24px;
	height: 24px;
	cursor: pointer;
	@include margin-right(10px);
	transform: rotate(0);

	html[dir="rtl"] &{
		transform: rotate(-180deg);
	}

	svg{
		fill: #000;
	}
}

.text{
	font-weight: 600;
	font-size: 16px;
	line-height: 1;
	text-transform: uppercase;
	color: #000000;
	display: flex;
	align-items: center;
}

.right{
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
}

.icon{
	width: 32px;
	height: 32px;
	padding: 4px;
	border-radius: 50%;
	cursor:pointer;

	svg{
		fill: #E74A3A!important;
	}

	&.isBreaking {
		background: #e2dfec;
	}
}

.menu{
	width:40px;
	height:40px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include margin(0, 0, 0, 10px);
}

.arrow{
	display:inline-block;
	width:16px;
	heigth:16px;
	@include margin-left(5px);

	svg{
		fill: #000;
	}
}