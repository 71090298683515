@import "../../../assets/styles/utils";

.body{
	margin: 26px 0 0 0;
}

.text{
	font-weight: 400;
	font-size: 20px;
	line-height: 1.5;
	color: var(--color-grey);

	p {
		margin:0 0 16px 0;
	}

	img{
		display: block;
		width: 100%;
		height: auto;
	}

	a{
		color: var(--color-link);
	}
}

:global{
	.external{
		font-weight: 400;
		font-size: 16px;
		line-height: 1.5;
		color: var(--color-link);
		transition: color $transition;

		&:after{
			position: relative;
			top: 2px;
			content: url("../../../assets/svg/external.svg");
			display: inline-block;
			width: 16px;
			height: 16px;
			margin: 0 4px;
			vertical-align: baseline;
		}
	}

	.passport{
		margin: 24px 0 0 0;

		dl{
			display: flex;
			gap: 8px;
			margin: 8px 0;
			padding: 0;
		}

		dt{
			font-weight: 500;
			font-size: 16px;
			line-height: 1.5;
			color: #323232;
			padding: 0;
			margin: 0;
			max-width: 150px;
		}

		dd{
			font-weight: 400;
			font-size: 16px;
			line-height: 1.5;
			color: #656565;
			padding: 0;
			margin: 0;
		}

		@include min1180{
			margin: 32px 0 0 0;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 8px 16px;

			dl{
				margin: 0;
			}
		}
	}

	//.grid{
	//	@include min1180{
	//		display: grid;
	//		grid-template-columns: 1fr 1fr;
	//	}
	//}
}