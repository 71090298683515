@import "../../../assets/styles/utils";

.burger{
	display: block;
	width: 40px;
	height: 40px;
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	@include margin-left(16px);

	i {
		position: absolute;
		display: block;
		height: 3px;
		width: 40px;
		background-color: #fff;
		left: 0;
		transform: rotate(0deg);
		transform-origin: left center;
		transition: .20s ease-in-out, opacity .1s ease;

		&:nth-child(1) {
			top: 10.5px;
		}

		&:nth-child(2){
			top: 18.5px
		}

		&:nth-child(3) {
			top: 26.5px;
		}
	}

	&.active{
		i{
			border-radius: 3px;

			&:nth-child(1) {
				transform-origin: left center;
				transform: rotate(45deg);
				width: 25px;
				left: 10px;
				top: 9px;
			}

			&:nth-child(2){
				transform-origin: center center;
				transform: rotateY(-90deg);
				opacity: 0;
			}

			&:nth-child(3) {
				transform: rotate(-45deg);
				transform-origin: left center;
				width: 25px;
				left: 10px;
				top: 27px;
			}
		}
	}
}