@import '../../assets/styles/utils';
@import '../../assets/styles/fonts';
@import '../../assets/styles/common.module';

.page{
	display: grid;
	min-height: 100vh;
	grid-template-rows: 60px 1fr 70px;
	grid-template-columns: 100%;
	grid-template-areas: "header" "content" "lenta";

	@include min1180{
		grid-template-rows: 60px 1fr 42px;
		overflow: hidden;
	}

	&.isLentaMini{
		grid-template-rows: 60px 1fr 0;

		.container{
			height: calc(100vh - 60px);
		}
	}

	&.topline{
		grid-template-rows: 40px 60px 1fr 42px;
		grid-template-columns: 100%;
		grid-template-areas: "topline" "header" "content" "lenta";

		@include min1180{
			.container{
				height: calc(100vh - 40px - 60px - 42px);
			}
		}

		&.isLentaMini{
			grid-template-rows: 40px 60px 1fr 0;

			.container{
				height: calc(100vh - 40px - 60px);
			}
		}
	}
}

.sticky{
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10000;
}

.wrapper{
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 12px;
	box-sizing: border-box;
}

.container{
	grid-area: content;
	z-index: 10;
	overflow: auto;
	transition: height .1s;

	@include min1180{
		height: calc(100vh - 60px - 42px);
	}
}

.containerInner{
	max-width: 1440px;
	margin: 0 auto;
	box-sizing: border-box;
	height:100%;
	padding:0;

	@include min1180{
		padding: 0 12px;
	}


	&.services{
		max-width:100%;
		padding:0;
		background-color: #010f4d;
		background-image: url('../../assets/images/services.png');
		background-position: bottom right;
		background-repeat: no-repeat;
		overflow:auto;
	}
}

.content{
	padding: 0 12px;

	@include min1180{
		padding:12px 6px;
	}
}

.article{
	display: grid;
	grid-template-rows: min-content 1fr;
	height: 100%;
	background: #fff;

	@include min1180{
		display:block;
		background: none;
	}
	
	&.block{
		display: block;
	}
}

.stickyContent{
	overflow: auto;
	padding: 0 12px;

	@include min1180{
		padding:12px 6px;
	}
}

.overflow{
	overflow: auto;
}

.height{
	height:100%;
}

.grid{
	display: grid;
	grid-template-rows: min-content 1fr;
	height: 100%;
	overflow: auto;

	@include min1180{
		display: grid;
		grid-template-columns: min-content 1fr;
		grid-template-rows: 1fr;
		height: 100%;
		padding: 0;
	}
}

.hide{
	&.active{
		display: none;
	}
}

.inner{
	@include min1180{
		padding: 12px 6px;
	}
}

.flex{
	@include min1180{
		display:flex;
		width: 100%;
		flex-wrap: wrap;
	}
}

.column{
	margin: 12px 0;

	@include min1180{
		margin: 4px 0;
		padding: 20px;
		width: 50%;
	}

	&.padding{
		@include min1180{
			margin: 4px 0;
			padding: 0 20px;
			width: 50%;
		}
	}

	&.private{
		background-color: var(--color-blue2);
		border-radius: 4px;
		height: max-content;
	}
}

.title{
	font-weight: 500;
	font-size: 18px;
	line-height: 1.44;
	color: #000000;
	margin: 0 0 8px 0;
}

.dl{
	display:flex;
	width:100%;
	padding: 16px 0;
	border-bottom:1px solid #D3D3D3;
	overflow: hidden;
}

.dt{
	font-weight: 500;
	font-size: 18px;
	line-height: 1.44;
	color: #000000;
	flex:1;
}

.dd{

}

.buttons{
	button{
		width:100%;
	}

	button + button {
		margin: 16px 0 0 0;
	}

	@include min1180{
		display: flex;
		width: 100%;
		box-sizing: border-box;
		flex-wrap: wrap;
		justify-content: flex-end;

		button{
			width: auto;
		}

		button + button {
			@include margin(0, 0, 0, 8px);
		}
	}
}

.sortWrapper{
	margin-top:8px;
}

.head{
	padding: 12px;
}

.tab{
	margin: 24px 0 24px 0;
	display:flex;
}

.tabItem{
	font-weight: 500;
	font-size: 20px;
	cursor:pointer;
	color: #A4A4A4;

	& + &{
		@include margin-left(12px);
	}

	&.active{
		color:var(--color-link);
	};
}