@import '../../../assets/styles/utils';

.title{
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	color:#000;
	padding:20px;

	@include min1180{
		padding:32px;
		max-width:360px;
	}
}

.body{
	padding:0 20px 20px;

	@include min1180{
		padding:0 32px 32px;
	}
}