@import "../../assets/styles/utils";

.slider{
	position: relative;
	margin-bottom: 10px;
}

.nav{
	position: absolute;
	display: block;
	top: 50%;
	margin-top: -27px;
	width: 54px;
	height: 54px;
	background: var(--color-blue2);
	box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	box-sizing: border-box;
	border: none;
	outline: none;
	cursor: pointer;
	z-index: 10;
	padding: 12px;
	transition: background-color $transition;

	svg{
		width: 30px;
		height: 30px;
		margin: auto;
		fill:#000;
	}

	&.prev{
		left: -27px;
	}

	&.next{
		right: -27px;
	}
}

.slide{
	img{
		width:100%;
		height:auto;
		display: block;
	}
}