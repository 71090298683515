@import "../../../assets/styles/utils";


.container{
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;
	@include margin(0, 4px, 0, 20px);
}

.button{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 40px;
	min-width: 40px;
	line-height: 1;
	padding: 0;
	margin: 0;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	transition: background-color $transition, color $transition;
	box-sizing: border-box;
	border: none;
	outline: none;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	background: none;
	color: #fff;
	white-space: nowrap;

	&.disabled{
		opacity: 0.7;
		pointer-events: none;
	}

	&.background{
		background: var(--color-blue);
	}
}

.buttonIcon{
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;

	svg{
		fill:#fff;
	}

	&.big{
		width: 40px;
		height: 40px;
	}
}

.buttonText{
	display: inline-block;
	vertical-align: middle;
	color: #fff;
	font-size: 12px;
	@include padding-right(8px);
}