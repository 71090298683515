@import "../../assets/styles/utils";

.history{
	max-width:860px;

	@include min1180{
		padding: 0 20px;
	}
}

.section{
	margin-bottom: 12px;
}

.list{

}