@import "../../../assets/styles/utils";

.container{
	padding-bottom: 4px;
}

.count{
	display: flex;
	height: 24px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	position: sticky;
	top: 0;
	z-index:100;
	@include padding(0, 12px, 0, 0);

	&.hasTitle{
		top:52px;
	}
}

.text{
	font-weight: 400;
	font-size: 12px;
	line-height: 1.33;
	color: #323232;
	padding:0 12px;
	text-transform: uppercase;
}