@import "../../../assets/styles/utils";

.bar{
	display: flex;
	height: 24px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 4px;
	position: sticky;
	top: 0;
	z-index:100;
	@include padding(0, 12px, 0, 0);

	//&.hasTitle{
	//	top:52px;
	//}
	//
	//&.hasCount{
	//	top: 80px;
	//}
	//
	//&.hasWarning{
	//	top: 96px;
	//
	//	&.hasCount{
	//		top: 124px;
	//	}
	//}
	&.top4{
		top: 4px!important;
	}
}

.date{
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 24px;
	padding: 0 12px;

	&:hover{
		svg: #000;
	}
}

.title{
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
	color: #000;
	@include padding-right(5px);
}

.arrow{
	display: inline-block;
	line-height: 1;
	vertical-align: middle;
	width: 16px;
	height: 16px;
}
.checkbox{
	label{
		margin: 0;
	}
}