@import "../../assets/styles/utils";


.telegram{
  position: relative;
  background: #f3f3f3;
  border-radius: 8px;
  overflow: hidden;
  z-index: 10;
}

.header{
  background: var(--color-main);
  color: #fff;
  padding: 4px 12px 6px;
  width: 100%;
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.icon{
  margin-top: 4px;
  display: block;
  width: 32px;
  height: 32px;

  svg{
    fill: #fff;
  }
}

.info{
  display: block;
  flex: 1;
}

.title{
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-decoration: none;
}

.counter{
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #DBDEE9;;
}

.list{
  padding: 8px;
  height: 430px;
  overflow: auto;
}

.post{
  padding: 12px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0px #0000000A;
  margin-bottom: 8px;
}

.postHeader{
  display: flex;
  width: 100%;
  gap: 6px;
  margin-bottom: 16px;
  text-decoration: none;
}

.postIcon{
  display: block;
  width: 32px;
  height: 32px;
}

.postInfo{
  display: block;
  flex: 1;
}

.postTitle{
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #545454;
  margin-bottom: 2px;
}

.postDate{
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #656565;
}

.postMedias{
  display: grid;
  gap: 8px;
  margin-bottom: 12px;

  & > * {
    margin: 0;
  }

  &.postMediasGrid{
    grid-template-columns: 1fr 1fr;
  }

  &.postMediasLarge{
    & > *:first-child{
      grid-column: 2 span;
    }
  }
}

.postBody{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  p{
    margin: 0 0 8px 0;
  }
}

.postLink{
  font-size: 14px;
  color: var(--color-link);
  transition: color $transition;

  &:hover{
    color: var(--color-link-hover);
  }
}