$color-main: #354577;

$padding: 12px;
$header: 60px;
$lenta: 42px;
$max-width: 1440px;

$box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);

$border-radius: 4px;

$transition: ease .2s;

$font: "Golos";

@mixin min480 {
	@media only screen and (min-width: 479px) {
		@content;
	}
}

@mixin min768 {
	@media only screen and (min-width: 767px) {
		@content;
	}
}

@mixin min1180 {
	@media only screen and (min-width: 1179px) {
		@content;
	}
}

@mixin min1440 {
	@media only screen and (min-width: 1439px) {
		@content;
	}
}

@mixin padding($p1, $p2, $p3, $p4){
	html[dir="rtl"] &{
		padding: $p1 $p4 $p3 $p2;
	}

	html[dir="ltr"] &{
		padding: $p1 $p2 $p3 $p4;
	}
}

@mixin padding-right($padding){
	html[dir="rtl"] &{
		padding-left: $padding;
	}

	html[dir="ltr"] &{
		padding-right: $padding;
	}
}

@mixin padding-left($padding){
	html[dir="rtl"] &{
		padding-right: $padding;
	}

	html[dir="ltr"] &{
		padding-left: $padding;
	}
}

@mixin margin($m1, $m2, $m3, $m4){
	html[dir="rtl"] &{
		margin: $m1 $m4 $m3 $m2;
	}

	html[dir="ltr"] &{
		margin: $m1 $m2 $m3 $m4;
	}
}

@mixin margin-right($margin){
	html[dir="rtl"] &{
		margin-left: $margin;
	}

	html[dir="ltr"] &{
		margin-right: $margin;
	}
}

@mixin margin-left($margin){
	html[dir="rtl"] &{
		margin-right: $margin;
	}

	html[dir="ltr"] &{
		margin-left: $margin;
	}
}

@mixin left($left){
	html[dir="rtl"] &{
		right: $left;
	}

	html[dir="ltr"] &{
		left: $left;
	}
}

@mixin right($right){
	html[dir="rtl"] &{
		left: $right;
	}

	html[dir="ltr"] &{
		right: $right;
	}
}

@mixin float($align){
	$varible: (
			"left": right,
			"right": left,
			"none": none
	);

	html[dir="rtl"] &{
		float: map-get($varible, $align);
	}

	html[dir="ltr"] &{
		float: $align;
	}
}

@mixin float-left(){
	html[dir="rtl"] &{
		float: right;
	}

	html[dir="ltr"] &{
		float: left;
	}
}

@mixin float-right(){
	html[dir="rtl"] &{
		float: left;
	}

	html[dir="ltr"] &{
		float: right;
	}
}

@mixin text-align($align){
	$varible: (
			"left": right,
			"right": left,
			"none": none
	);

	html[dir="rtl"] &{
		text-align: map-get($varible, $align);
	}

	html[dir="ltr"] &{
		text-align: $align;
	}
}

@mixin border-right($border){
	html[dir="rtl"] &{
		border-left: $border;
	}

	html[dir="ltr"] &{
		border-right: $border;
	}
}

@mixin border-left($border){
	html[dir="rtl"] &{
		border-right: $border;
	}

	html[dir="ltr"] &{
		border-left: $border;
	}
}

@mixin border-width($a1, $a2, $a3, $a4){
	html[dir="rtl"] &{
		border-width: $a1 $a2 $a3 $a4;
	}

	html[dir="ltr"] &{
		border-width: $a1 $a2 $a3 $a4;
	}
}

@mixin border-radius($a1, $a2, $a3, $a4){
	html[dir="rtl"] &{
		border-radius: $a3 $a4 $a1 $a2;
	}

	html[dir="ltr"] &{
		border-radius: $a1 $a2 $a3 $a4;
	}
}

@mixin inset($a1, $a2, $a3, $a4){
	html[dir="rtl"] &{
		inset: $a3 $a4 $a1 $a2!important;
	}

	html[dir="ltr"] &{
		inset: $a1 $a2 $a3 $a4!important;
	}
}