@import "../../../assets/styles/utils";

.container{
	margin-bottom: 8px;
}

.icon{
	background: var(--color-light);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	width:32px;
	height:32px;
	cursor:pointer;

	svg{
		fill: var(--color-grey);
	}

	&:hover{
		svg{
			fill:#000;
		}
	}
}