@import "../../../assets/styles/utils";

.container{
	position: relative;
}

.button{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 40px;
	min-width: 40px;
	line-height: 1;
	padding: 0;
	margin: 0;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	transition: background-color $transition, color $transition;
	box-sizing: border-box;
	border: none;
	outline: none;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	background: none;
	color: #fff;
	white-space: nowrap;

	&.disabled{
		opacity: 0.7;
		pointer-events: none;
	}

	&.background{
		background: var(--color-blue);
	}
}

.buttonIcon{
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;

	svg{
		fill:#fff;
	}

	&.big{
		width: 40px;
		height: 40px;
	}
}

.buttonText{
	display: inline-block;
	vertical-align: middle;
	color: #fff;
	font-size: 12px;
	@include padding-right(8px);
}

.dropdown{
	position: absolute;
	top:100%;
	@include left(0);
	width: 270px;
	background: var(--color-light);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 12px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	will-change: transform;
	margin-top: 3px;
	transform: translateY(10px);
	opacity: 0;
	visibility: hidden;
	transition: .3s ease transform, .3s ease opacity;


	&.active {
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
	}
}

.title{
	padding: 12px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
	color: var(--color-grey);
}

.scroll{
	margin: 0 12px;
	border-radius: 4px;
	overflow: hidden;
}

.search{
	background: #fff;
	border-bottom: 1px solid var(--color-input);

	label{
		margin: 0;
	}

	input{
		border: none;
	}
}

.list{
	background: #fff;
	border-radius: 0 0 4px 4px;
	max-height: 190px;
	overflow: auto;
	padding: 12px 12px 0;
}

.empty{
	display: flex;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 12px;
	line-height: 1.15;
	font-weight: 400;
	font-size: 14px;
	color: #656565;
}

.footer{
	margin: 0 12px;
	padding: 6px 0;

	button{
		height: 30px;
		line-height: 30px;
	}
}