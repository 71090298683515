@import "../../assets/styles/utils";

.article{
	position:relative;
	padding: 0 12px;

	@include min1180{
		box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
		background: #fff;
		border-radius: 4px;
		padding: 24px 32px;
	}

	&.preview{
		box-shadow: none;
		background: transparent;
		padding:24px 20px 300px;

		@include min1180{
			box-shadow: none;
			background: transparent;
			padding: 24px 20px 50vh;
		}
	}

	&[data-dir='rtl']{
		direction: rtl;

		.large{
			margin-right: 8px;
			margin-left: 0;
		}
	}
}

.header{
	margin-top:22px
}

.back{
	position: absolute;
	top:-5px;
	@include left(-5px);
}

.info{
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-bottom:16px;
	align-items: center;
}

.left{
	display: flex;
	align-items: center;
	gap:12px;
}

.exclusive{
	display: inline-block;
	font-size: 12px;
	height: 20px;
	line-height: 19px;
	border-radius: 20px;
	padding: 0 8px;
	background: var(--color-blue);
	color: #fff;
	box-sizing: border-box;
}

.btns{
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.size{
	display:flex;
	flex-wrap:nowrap;
	align-items: flex-end;
	padding: 0 12px;
}

.small{
	width:12px;
	height:13px;
	margin-bottom:1px;
	cursor:pointer;

	svg{
		fill: var(--color-grey);
		opacity:.4;
		transition: fill $transition;
	}

	&:hover{
		svg{
			fill:#000;
		}
	}
}

.large{
	width:16px;
	height:18px;
	cursor:pointer;
	@include margin-left(8px);

	svg{
		fill: var(--color-grey);
		transition: fill $transition;
	}

	&:hover{
		svg{
			fill:#000;
		}
	}
}

.controls{
	position:sticky;
	bottom:15px;
}

.wrapper{
	position:absolute;
	bottom:0;
	@include left(-40px);
}