@import '../../../assets/styles/utils';

.header{
	position:relative;
}

.arrow{
	position: absolute;
	bottom: 10px;
	width: 20px;
	height: 20px;
	@include right(10px);
}

.close{
	position: absolute;
	bottom: 0;
	width: 40px;
	height: 40px;
	@include right(0);
}

.fieldTitle{
	position: static;
	margin-bottom: 5px;
	font-size: 12px;
	color: var(--color-grey);
	background: transparent;
}

.row{
	margin-bottom: 16px;
}

.add{
	color: var(--color-link);
	font-size: 14px;
	margin-top: 8px;
	cursor: pointer;
	height: 16px;
	line-height: 16px;
	transition: color $transition;

	&:hover{
		color: var(--color-dark);

		.addIcon{
			svg{
				fill: var(--color-dark);
			}
		}
	}
}

.addIcon{
	display: inline-block;
	width: 16px;
	height: 16px;
	vertical-align: middle;
	margin-top: -2px;
	@include margin-left(2px);

	svg{
		transition: fill $transition;
		fill: var(--color-link);
	}
}

.create{
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(#000, .1);
}

.background{
	position: absolute;
	height: 210px;
	top: 0;
	left: 0;
	right: 0;
}

.wrapper{
	position: absolute;
	top: 210px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	padding: 0 0 70px 0;
}

.inner{
	height: 100%;
}

.list{
	max-height: calc(100% - 25px);
	padding: 16px 20px 0;
	overflow: auto;
}

.footer{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;
}

.devider{
	border-bottom: 1px solid #D3D3D3;
	margin: 0 20px 8px;
}

.settings{
	display: flex;
	align-items: center;
	margin: 0 20px;
	gap: 4px;
	font-size: 14px;
	color: var(--color-link);
	cursor: pointer;
	transition: color $transition;

	&:hover{
		color: var(--color-dark);

		.settingsIcon{
			svg{
				fill:var(--color-dark);
			}
		}
	}
}

.settingsIcon{
	display: inline-block;
	width: 14px;
	height: 14px;

	svg{
		transition: fill $transition;
		fill: var(--color-link);
	}
}

.listContainer{
	margin-bottom: 16px;
}

.listMail{
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-bottom: 16px;
}

.listMailItem{
	background-color: var(--color-light);
	padding: 0 8px;
	height: 24px;
	line-height: 24px;
	color:#656565;
	font-size: 12px;
	border-radius: 100px;
}

.listMailHeader{
	display: flex;
	gap: 11px;
	margin-bottom: 8px;

	label{
		margin: 0;
		flex: initial;
	}
}

.listMailToggle{
	display: inline-block;
	height: 16px;
	line-height: 16px;
	font-size: 12px;
	color: var(--color-grey);
	padding: 0 5px;
	background-color: var(--color-light);
	border-radius: 30px;
	cursor: pointer;
	user-select: none;

	&:hover{
		color: #000;

		.listMailToggleIcon{
			svg{
				fill:#000;
			}
		}
	}
}

.listMailToggleIcon{
	display: inline-block;
	width: 14px;
	height: 14px;
	vertical-align: middle;
	@include margin-left(4px);

	svg{
		fill:var(--color-grey);
	}
}