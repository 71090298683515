@import "../../assets/styles/utils";


.dropwdown {
	position: relative;
	box-sizing: border-box;
	width: 100%;

	&.disabled{
		.header{
			cursor: auto;
		}
	}
}

.header{
	cursor:pointer;
}

.content {
	position: fixed;
	margin-top: 3px;
	transform: translateY(10px);
	opacity: 0;
	visibility: hidden;
	transition: .3s ease transform, .3s ease opacity;
	z-index: 10000000;
	will-change: transform;
	padding: 8px 0;
	box-shadow: rgb(0 0 0 / 4%) 2px 2px 3px, rgb(0 0 0 / 10%) -1px 12px 20px;
	border-radius: 4px;
	background: var(--color-light);
	overflow: auto;

	&.fade{
		margin-top: 0;
		transform: translateY(0);
		border: none;
		box-shadow: 0 4px 8px rgba(0, 15, 90, 0.16);
		border-radius: 3px;
		transition: .3s ease opacity;
	}

	&.active {
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
	}


	&.white{
		background: #fff;

		.item{
			&:hover{
				background-color: var(--color-light);
			}

			&.active {
				color: var(--color-link);

				svg {
					fill:var(--color-link);
				}
			}

			&.link{
				color: var(--color-link);
				background-color: #fff;

				svg{
					fill: var(--color-link);
				}

				&:hover{
					color: var(--color-dark);

					svg{
						fill: var(--color-dark);
					}
				}
			}
		}
	}

	&.list{
		display: flex;
		padding: 0 6px;

		.item {
			padding:8px 4px;
		}
		
		.itemIcon{
			display: none;
		}
	}

	&.mini{
		background: #F3FAFE;
		max-height: 300px;
		overflow: auto;

		.item{
			padding: 3px 10px;
			font-size: 12px;
		}

		.itemIcon{
			display: none;
		}
	}
}

.item{
	cursor: pointer;
	color: var(--color-grey);
	padding: 8px 12px 8px 8px;
	transition: color $transition, background-color $transition;
	display: flex;
	width: 100%;
	gap: 8px;

	&:hover{
		color: #000;

		.itemIcon{
			svg{
				fill: #000;
			}
		}
	}

	&.active{
		color: var(--color-link);

		.itemIcon{
			svg{
				fill: var(--color-link);
			}
		}
	}

	&.list{
		padding: 4px 10px;

		&:hover,
		&.active{
			color: #000;
		}
		&.active{
			font-weight: 600;
		}
	}
}

.itemIcon{
	width: 16px;
	height: 16px;

	svg{
		fill: var(--color-grey);
	}
}

.itemText{
	flex:1;
}

.checkbox{
	padding: 8px 12px 8px 8px;

	label{
		margin: 0;
	}
}

.input{
	margin-top: -4px;
	padding: 4px 8px 4px;
	background: #fff;
	position: sticky;
	top: -4px;

	& > div,label{
		margin: 0;
	}
}

.headerContainer{
	&.large{
		.headerLabel{
			height: 40px;
			line-height: 40px;

			&.active{
				border-color: #D9E3F7;
			}
		}
	}

	&.light{
		.headerTitle{
			color: #fff;
		}

		.headerLabel{
			background: transparent;
			border-color: var(--color-border);
		}

		.headerText{
			color:#fff;
		}
		
		.headerInput{
			color: #fff;
		}
	}
}

.headerTitle{
	font-weight: 400;
	font-size: 12px;
	color: var(--color-grey);
	margin-bottom: 4px;
}

.headerLabel{
	position: relative;
	background: #FFFFFF;
	border: 1px solid #D9E3F7;
	box-sizing: border-box;
	border-radius: 4px;
	color: #000;
	display: block;
	width: 100%;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	box-sizing: border-box;
	outline: none;
	padding: 0 30px 0 12px;
	transition: border-color $transition;

	&.active{
		border-color: var(--color-blue);

		.headerIcon{
			transform: rotate(-180deg);
		}
	}

	&.error{
		border-color: var(--color-red);
	}
}

.headerContent{
	height: 100%;
	display: flex;
	align-items: center;
	gap: 8px;
}

.headerInput{
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	outline: none;
	color:#000;
}

.headerText{
	color: #000;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	&.headerPlaceholder{
		color: #999999;
	}
}

.headerCount{
	display: inline-block;
	height: 16px;
	font-size: 12px;
	line-height: 16px;
	padding: 0 6px;
	background: #EDF8FF;
	border-radius: 100px;
}

.headerIcon{
	position: absolute;
	right: 5px;
	top: 50%;
	margin-top: -10px;
	width: 20px;
	height: 20px;
	display: block;
	line-height: 1;

	svg{
		fill:#D9E3F7;
	}

	&.headerLoading{
		svg{
			stroke: var(--color-grey);
		}
	}
}

.button{
	position: sticky;
	bottom: -4px;
	display: block;
	width: 100%;
	font-weight: 600;
	font-size: 12px;
	text-transform: uppercase;
	height: 34px;
	line-height: 34px;
	color: var(--color-link);
	cursor: pointer;
	background: #fff;
	border: none;
	padding: 0;

	&:hover{
		color: var(--color-dark);
	}
}

.footer{
	border-top: 1px solid var(--color-link);
	padding: 4px 0 0;
}