@import "../../../assets/styles/utils";

.media{
	display: block;
	margin: 16px 0 15px;
	position:relative;
}

.link{
	div{
		padding: 0;
	}

	img{
		position: static;
		transform: none;
		display: block;
		width: auto;
		height: auto;
	}
}

.navigation{
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	margin-top: -27px;
	width: 54px;
	height: 54px;
	background: var(--color-blue2);
	box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	box-sizing: border-box;
	border: none;
	outline: none;
	cursor: pointer;
	z-index: 10;
	padding: 7px;
	transition: background-color $transition;

	&.next {
		@include right(-14px);

	}

	&.prev {
		@include left(-14px);
	}

	span{
		width: 30px;
		height: 30px;
		margin: auto;

		html[dir="rtl"] & {
			transform: rotate(-180deg);
		}
	}
}