@import "../../../assets/styles/utils";
@import "../../../assets/styles/common.module";

.news{
  border-radius: 8px;
  background: #FFFFFF;
  padding: 12px;
  box-shadow: 0 2px 24px 0 #7070701F;
  margin-bottom: 24px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  &.minHeight{
    min-height: 482px;
  }

  @include min768{
    margin: 0;
  }
}

.title{
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #000;
  margin-bottom: 16px;
  cursor: pointer;
  transition: color $transition;

  &:hover{
    color: var(--color-link);
  }
}

.media{
  margin-bottom: 12px;
}

.announce{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #656565;
  margin-bottom: 12px;
}

.link{
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--color-link);
  transition: color $transition;
  cursor: pointer;

  &:hover{
    color: var(--color-dark);
  }
}

.blink {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
}

.blinkIcon{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate(-100%);
  animation: blink 2s infinite;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform:rotate(10deg);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 17.81%, rgba(255, 255, 255, 0.7) 42.17%, rgba(255, 255, 255, 0.8) 50.78%, rgba(255, 255, 255, 0.4) 57.68%, rgba(255, 255, 255, 0) 62.41%);

    //background: linear-gradient(90deg,
    //        rgba(255, 255, 255, 0) 0,
    //        rgba(255, 255, 255, 0) 30%,
    //        rgba(255, 255, 255, 0.7) 45%,
    //        rgba(255, 255, 255, .9) 50%,
    //        rgba(255, 255, 255, 0.7) 55%,
    //        rgba(255, 255, 255, 0) 70%,
    //        rgba(255, 255, 255, 0) 100%
    //);
  }
}

@keyframes blink {
  100% {
    transform: translate(100%);
  }
}