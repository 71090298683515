@import "../../../assets/styles/utils";


.empty{
	height: 150px;
	font-size:20px;
	display:flex;
	width:100%;
	align-items:center;
	justify-content: center;
	color: var(--color-grey);
}