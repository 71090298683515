@import "../../../assets/styles/utils";

.label{
	display: block;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 16px;
	flex: 1;

	&.focus{
		.title{
			top: -7px;
			font-size: 12px;
		}
	}

	&.hasvalue{
		top: -7px;
		font-size: 12px;
	}

	&.light{
		.input{
			border-color: var(--color-border);
			color: #fff;
		}

		.title{
			position: static;
			margin-bottom: 2px;
			font-size: 12px;
			color: #DBDEE9;
			background: transparent;
		}
	}

	&.grey{
		.input{
			border-color: var(--color-input);
			color: #000;
		}

		.title{
			position: static;
			margin-bottom: 2px;
			font-size: 12px;
			color: var(--color-grey);
			background: transparent;
		}
	}
}

.title{
	font-weight: 400;
	font-size: 14px;
	color: var(--color-grey);
	position: absolute;
	top: 7px;
	background-color: #fff;
	transition: all $transition;
	@include left(13px);
}

.input{
	display: block;
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	color: #000;
	background: none;
	outline: none;
	box-sizing: border-box;
	border: 1px solid var(--color-input);
	border-radius: 4px;
	min-height: 120px;
	padding: 4px 12px;
	resize: vertical;
}