@font-face {
	font-family: 'Golos';
	src: url('../fonts/Golos-Text_Regular.woff2') format('woff2'), url('../fonts/Golos-Text_Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Golos';
	src: url('../fonts/Golos-Text_Medium.woff2') format('woff2'), url('../fonts/Golos-Text_Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Golos';
	src: url('../fonts/Golos-Text_Bold.woff2') format('woff2'), url('../fonts/Golos-Text_Bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'PT Sans';
	src: local("PT Sans"), url("../fonts/PTS55F_W.woff") format("woff"),
		url("../fonts/PTS55F_W.ttf") format("truetype");
}

@font-face {
	font-family: 'PT Sans';
	font-style: italic;
	src: local("PT Sans Italic"), url("../fonts/PTS56F_W.woff") format("woff"),
		url("../fonts/PTS56F_W.ttf") format("truetype");
}

@font-face {
	font-family: 'PT Sans';
	font-style: normal;
	font-weight: 600;
	src: local("PT Sans Bold"), url("../fonts/PTS75F_W.woff")
		format("woff"), url("../fonts/PTS75F_W.ttf") format("truetype");
}