@import "../../../../../assets/styles/utils";


.controls{
	position: absolute;
	left: 0;
	right: 0;
	color:#fff;
	bottom: 0;
	height: 68px;
	transition: opacity .2s ease;
	opacity:0;
	background: linear-gradient(0deg, rgba(0,0,0,.15) 0%, rgba(0,0,0,.15) 90%, rgba(0,0,0,0) 100% );

	&:hover{
		opacity:1;
	}

	&.show{
		opacity: 1;

		.progressMouse{
			opacity: 1;
		}
	}

	&.move{
		opacity: 1;
	}

	&.over{
		opacity: 1;
	}
}

.play{
	position:absolute;
	left: 7px;
	bottom: 15px;
	width: 40px;
	height: 40px;
	cursor:pointer;
	background: none;
	border: none;
	color: inherit;
	display: inline-block;
	font-size: inherit;
	line-height: inherit;
	text-transform: none;
	text-decoration: none;
	transition: none;
	appearance: none;
	text-align: center;
	margin: 0;
	padding: 0;

	svg{
		fill: #fff;
	}
}

.time{
	position: absolute;
	bottom: 25px;
	left: 55px;
}

.duration{
	position: relative;
	text-align: center;
	margin: 0;
	padding: 0;
	outline: 0;
	display: inline-block;
	min-width: 20px;
	width: auto;
	font-weight: 600;
	color:#fff;
	height: 20px;
	font-size: 16px;
	line-height: 20px;
}

.devide{
	display: inline-block;
	padding: 0 5px;
	height: 20px;
	line-height: 20px;
	font-size: 16px;
}

.soundBar{
	display: inline-block;
	text-align: center;
	margin: 0;
	padding: 0;
	outline:0;
	position: absolute;
	left: 5px;
	width: 30px;
	height: 105px;
	background-color: rgba(0,0,0,.8);
	border-radius: 3px;
	transition: opacity .3s ease;
	opacity: 0;
	visibility: hidden;
	bottom: 100%;
}

.sound{
	text-align: center;
	margin: 0;
	padding: 0;
	height: 40px;
	width: 40px;
	outline: 0;
	position: absolute;
	bottom: 15px;
	right: 60px;
	z-index: 2;
	display: block;

	&:hover,
	&.move{
		.soundBar {
			opacity: 1;
			visibility: visible;
		}
	}
}

.soundIcon{
	width: 40px;
	height: 40px;
	cursor:pointer;
	background: none;
	border: none;
	color: inherit;
	display: inline-block;
	font-size: inherit;
	line-height: inherit;
	text-transform: none;
	text-decoration: none;
	transition: none;
	appearance: none;
	text-align: center;
	margin: 0;
	padding: 0;

	svg{
		fill: #fff;
	}
}

.soundSlider{
	margin: 10px;
	width: 10px;
	height: 85px;
	border-radius: 2px;
	background-color: rgba(255,255,255,.15);
	overflow: hidden;
	position: relative;
	cursor: pointer;
	padding: 0;
	user-select: none;
	position: relative;
}

.soundVolume{
	position: absolute;
	bottom: 0;
	width: 100%;
	box-sizing: border-box;
	background-color: #fff;
}

.fullscreen{
	position:absolute;
	right: 13px;
	bottom: 15px;
	width: 40px;
	height: 40px;
	cursor:pointer;
	background: none;
	border: none;
	color: inherit;
	display: inline-block;
	font-size: inherit;
	line-height: inherit;
	text-transform: none;
	text-decoration: none;
	transition: none;
	appearance: none;
	text-align: center;
	margin: 0;
	padding: 0;

	svg{
		fill: #fff;
	}
}


.progress{
	display: inline-block;
	text-align: center;
	margin: 0;
	outline: 0;
	position: absolute;
	left: 10px;
	right: 10px;
	top: 0;
	width: auto;
	min-width: 40px;
	height: 12px;
	-ms-touch-action: none;
	touch-action: none;
	padding: 4px 0;
	cursor:pointer;
	box-sizing:border-box;
}

.progressPadding{
	position: relative;
	pointer-events: none;
	width: 100%;
	height: 100%;
}

.progressLoad{
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	margin: 0;
	padding: 0;
	height: 100%;
	width:100%;
	background: rgba(84,84,84,.5);
	pointer-events: none;
}

.progressMouse{
	display: none;
	position: absolute;
	width: 1px;
	height: 100%;
	background-color: #000;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	transition: visibility 1s,opacity 1s;
	pointer-events: none;

	&.active{
		display:block;
		visibility: visible;
		opacity: 1;
	}
}

.progressTooltip{
	border-radius: 2px;
	float: right;
	font-size: 8px;
	line-height: 18px;
	padding: 0 8px;
	pointer-events: none;
	position: absolute;
	top: -29px;
	z-index: 1;
	color: #fff;
	background-color: rgba(0,0,0,.8);
	left:50%;
	transform: translateX(-50%);
}

.progressCurrent{
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	margin: 0;
	padding: 0;
	height: 100%;
	background: #fff;
	z-index: 2;
	pointer-events: none;
}