@import "../../assets/styles/utils";


.list{
	flex: 1;
	height: 100%;
	overflow: auto;

	@include min1180{
		@include padding(12px, 0, 12px, 6px);
	}

	&.noPaddingTop{
		padding-top: 0!important;
		overflow: hidden;

		.title{
			top: 12px;
		}

		@include min768{
			overflow: auto;
		}
	}
}

.inner{
	padding: 0 12px 0 12px;

	@include min1180{
		padding:0;
	}
}

.title{
	position: sticky;
	top:0;
	z-index:122;
}

.spinner{
	position: relative;
	height: 80px;
	opacity: 0;

	&.isLoading {
		opacity: 1;
	}
}