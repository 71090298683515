@import "../../../assets/styles/utils";

.header{
	&.sm{
		.inputs{
			height: 32px;
			line-height: 30px;
		}
	}

	&.smFieldWidth{
		.field{
			min-width: 110px;
			width:auto;
		}
	}

	&.light{
		.title{
			color:#fff;
		}

		.inputs{
			border-color: var(--color-border);
			color: #fff;
		}

		.field{
			input{
				color:#fff;
			}

			input::placeholder {
				color:#b8b8b8;
			}

			&:nth-child(1){
				&:after{
					border-color: var(--color-border);
				}
			}

			&:nth-child(2){
				&:after{
					border-color: var(--color-border);
				}
			}
		}

		.label{
			color:#fff;
		}

		.icon{
			svg{
				fill:#fff;
			}

			&:hover{
				svg{
					fill:#fff;
				}
			}
		}

		.remove{
			svg{
				fill:#ACB4CD;
			}
		}
	}

	&.disabled{
		pointer-events: none;
		opacity: .3;
	}
}

.title{
	font-weight: 400;
	font-size: 12px;
	line-height: 1.17;
	color: var(--color-grey);
	margin-bottom: 5px;
}

.wrapper{
	display: grid;
	grid-template-columns: 1fr;
	//grid-template-columns: 1fr 32px;
	align-items:center;
}

.inputs{
	position:relative;
	border: 1px solid var(--color-input);
	border-radius: 4px;
	display:flex;
	height: 40px;
	line-height: 38px;
	flex: 1;
}

.field{
	display:flex;
	width:130px;
	position: relative;
	padding:0 12px;
	align-items: center;
	flex-basis: min-content;

	&:nth-child(1){
		&:after{
			content: '';
			position:absolute;
			top:7px;
			bottom:7px;
			display:block;
			border-right: 1px solid var(--color-input);
			@include right(0);
		}
	}

	&:nth-child(2){
		&:after{
			content: '';
			position: absolute;
			top: -1px;
			bottom: -1px;
			display: block;
			border-right: 1px solid var(--color-input);
			border-radius: 4px;
			width: 8px;
			z-index:-1;
			@include right(0);
		}
	}

	input{
		background: none;
		border: none;
		width:100%;
		outline: none;
		line-height: inherit;
		padding:0;
		margin:0;
		color: #323232;
		font-size: 14px;
	}
}

.checkbox{
	position: relative;
	white-space: nowrap;
	padding: 0 6px;
	white-space: nowrap;
	display: flex;
	align-items: center;
	line-height: 1.2;
	width: 100%;
	justify-content: space-between;

	label{
		margin:0;
	}
}

.label{
	font-weight: 400;
	font-size: 14px;
	color: #323232;
	@include padding-right(4px);
}

.tag{
	position: relative;
	background: var(--color-border);
	border-radius: 4px;
	height: 24px;
	line-height:24px;
	color:#fff;
	margin: 3px 0 3px 0;
	white-space: nowrap;
	@include padding(0, 32px, 0, 8px);
}

.remove{
	position: absolute;
	top: -10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor:pointer;
	width: 18px;
	height: 18px;
	@include right(-8px);

	span{

		display: block;
	}

	svg{
		display: block;
		width: 16px;
		height: 16px;
		cursor:pointer;
		fill:#B8B8B8;
		margin: -2px;
	}

	&.hasValue {
		svg{
			fill:#323232!important;
		}
		&:hover{
			svg{
				fill:#000!important;
			}
		}
	}
}

.removeIcon{
	position: relative;
	width:12px;
	height:12px;
	border-radius: 50%;
	background: var(--color-light);
}

.close{
	position: absolute;
	width:16px;
	height:16px;
	border-radius: 50%;
	background: var(--color-main);
	top: 50%;
	margin-top: -8px;
	cursor: pointer;
	@include right(8px);

	span{
		margin: -2px;
		width: 20px;
		height: 20px;
	}

	svg{
		fill:#fff;
	}
}

.icon{
	width: 26px;
	height: 26px;
	padding: 3px;
	cursor:pointer;
	@include margin-left(6px);

	svg{
		fill: #B8B8B8;
		transition: .2s ease fill;
	}

	&:hover{
		svg{
			fill: var(--color-grey);
		}
	}
}