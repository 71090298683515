@import "../../assets/styles/utils";

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -124;
	}
}

.loader{
	position: absolute;
	left:50%;
	top:50%;
	display: block;
	max-width: 100%;
	max-height: 100%;
	transform: translate(-50%,-50%);

	svg{
		stroke: var(--color-blue);
		animation: rotate 2s linear infinite;
		height: 100%;
		transform-origin: center center;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		margin: auto;
	}

	circle{
		stroke-dasharray: 150,200;
		stroke-dashoffset: -10;
		animation: dash 1.5s ease-in-out infinite;
		stroke-linecap: round;
	}
}