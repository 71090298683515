@import "../../assets/styles/utils";

.telegram{
	@include min1180{
		display: flex;
		flex-direction: column;
	}
}

.widget{
	position: relative;
	width: 100%;
	background-color: #fff;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	min-height: 335px;
	max-width:500px;
	margin:0 auto 10px;

	@include min1180{
		flex: 1;
		margin: 0 0 10px 0;
	}

	iframe{
		display: block;
		position: absolute;
		left:0;
		top:0;
		height:100%;
		width:100%;
	}
}

.buttons{
	@include min1180{
		display: block;
		gap:0;
	}
}

.btn{
	flex: 1;
	background-color: #fff;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 8px 12px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.icon{
	display: block;
	background: #34AADF;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	overflow: hidden;
	@include  margin-right(16px);

	svg{
		width: 100%;
		height: 100%;
		fill: #fff!important;
	}
}

.text{
	display: block;
	flex: 1;
}

.title{
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.43;
	color: #000;
}

.lead{
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.43;
	color: #34AADF;
}