@import "../../../assets/styles/utils";

.label{
	display: block;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 16px;

	&:hover{
		.title{
			color:#000;
		}
	}

	&.checked{
		.icon{
			&:after{
				display:block;
			}
		}
	}

	&.disabled{
		pointer-events: none;
		opacity: .5;
	}

	&.horizontal{
		display: inline-block;
		@include margin-right(16px);
	}

	&.start{
		.container{
			flex-direction: row-reverse;
		}

		.title{
			@include padding(0, 6px, 0, 0);
		}
	}

	&.blue{
		&.checked{
			.title{
				color:var(--color-link);
			}
		}
	}

	&.light{
		&:hover{
			.title{
				color: #fff;
			}
		}

		.title{
			color: #fff;
		}

		.icon{
			border-color: var(--color-light2);
		}
		&.checked{
			.icon {
				&:after {
					background-color:var(--color-light2);
				}
			}
		}
	}
}

.container{
	display: flex;
	align-items: center;
	cursor: pointer;
}

.title{
	font-weight: 400;
	font-size: 14px;
	color: var(--color-grey);
	flex: 1;
	@include padding(0, 0, 0, 6px);
	overflow: hidden;
}

.input{
	display:none;
	position: absolute;
}

.icon{
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	border: 2px solid var(--color-link);
	border-radius: 50px;
	box-sizing: border-box;
	transition: background-color $transition;

	&:after{
		content: '';
		display: none;
		position: absolute;
		top: 2px;
		left: 2px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: var(--color-link);
	}
}