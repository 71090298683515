@import "../../../../assets/styles/utils";

.photo{
	display:block;
	position: relative;
	padding-top: 56.25%;
	overflow:hidden;

	img{
		position:absolute;
		width:100%;
		top:50%;
		left:50%;
		transform: translate(-50%, -50%);
		object-fit:cover;
	}
}