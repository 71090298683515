@import "../../assets/styles/utils";

.header{
	position: sticky;
	top: 0;
	background-color: var(--color-header);
	box-sizing: border-box;
	grid-area: header;
	z-index: 1000;

	&.active{
		z-index: 10000;
	}
}

.wrapper{
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 12px;
	box-sizing: border-box;
	height: 60px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.logo{
	width: 158px;
	@include margin-right(25px);
	box-sizing: content-box;
	transition: width $transition, padding $transition, margin $transition;

	svg{
		fill: #fff;
	}

	@include min1180{
		@include margin-right(100px);
	}

	&.hidden{
		opacity: 0;
		margin: 0!important;
		width: 0;
	}
}

.overlay{
	position: fixed;
	top:60px;
	left:0;
	right:0;
	bottom:0;
	background: red;
	z-index:10;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	visibility: hidden;
	transition: .2s ease transform, .2s ease opacity;

	&.topline{
		top: 100px;
	}

	&.active{
		visibility: visible;
		opacity: 1;
	}
}