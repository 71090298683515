@import "../../assets/styles/utils";

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -124;
	}
}

@keyframes color {
	0% {
		stroke: #fff;
	}
	40% {
		stroke: #fff;
	}
	66% {
		stroke: #fff;
	}
	80%, 90% {
		stroke: #fff;
	}
}

.content{
	display: flex;
	width: 100%;
	height: 100%;
	flex-wrap: nowrap;
	align-items: center;
	gap: 8px;
}

.icon{
	display: block;
	position: relative;
	width: 20px;
	height: 20px;

	span{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	
	svg{
		display: block;
	}
}

.children{
	flex: 1;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
}

.spinner{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	span{
		display: inline-block;
		vertical-align: middle;
		line-height: 1;
		width: 28px;
		height: 28px;
	}

	svg{
		stroke: var(--color-blue);
		animation: rotate 2s linear infinite;
		height: 100%;
		transform-origin: center center;
		width: 100%;
		margin: auto;
	}

	circle{
		stroke-dasharray: 150,200;
		stroke-dashoffset: -10;
		animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
		stroke-linecap: round;
	}
}

.wrapper{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 32px;
	line-height: 32px;
	padding: 0 16px;
	margin: 0;
	background: var(--color-link);
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	transition: background-color $transition, color $transition;
	box-sizing: border-box;
	border: none;
	outline: none;
	color: #fff;
	min-width: auto;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;

	//&:hover{
	//	background: var(--color-main);
	//}

	& + button {
		@include margin-left(8px);
	}

	&.isLoading{
		cursor: auto;
		pointer-events: none;

		.content{
			opacity: 0;
		}
	}

	&.disabled{
		cursor: auto;
		pointer-events: none;
		opacity:.7;
	}

	&.startIcon{
		@include padding-left(0);
	}

	&.endIcon{
		@include padding-right(0);
	}


	//variant
	&.text{
		background: none;
		color: var(--color-grey);

		svg{
			fill: var(--color-grey);
		}

		&:hover{
			color:#000;

			svg{
				fill: #000;
			}
		}
	}

	&.textWhite{
		background-color: transparent;
		padding: 0;
		color: #fff;

		&:hover{
			color: var(--color-input);

			svg{
				fill: var(--color-input);
			}
		}

		svg{
			//width:20px;
			//height:20px;
			fill: #fff;
		}
	}


	&.textBlue{
		background: none;
		color: var(--color-link);
		padding: 0;

		&:hover{
			color: var(--color-dark);

			svg{
				fill: var(--color-dark);
			}
		}

		svg{
			fill: var(--color-link);
		}

		.content{
			gap:4px;
		}

		.icon{
			width: 16px;
			height: 16px;
		}
	}

	&.d{
		background-color: transparent;
		padding: 0;
		font-size: 12px;
		color: var(--color-grey);

		&:hover{
			color:#000;
		}

		svg{
			fill: var(--color-grey);
		}
	}

	&.light{
		background-color: var(--color-light);
		color: var(--color-darkBlue);

		svg{
			fill: var(--color-darkBlue);
		}
	}

	&.transparentLight{
		background: none;
		color: var(--color-grey);
		border: 1px solid var(--color-light2);

		svg{
			fill: var(--color-grey);
		}
	}

	&.white{
		background-color: #fff;
		color: var(--color-grey);

		&:hover{
			color: #000;
		}
	}

	&.delete{
		background: none;
		color: var(--color-red);

		&:hover{
			color: var(--color-red);
		}

		svg{
			fill: var(--color-red);
		}
	}

	&.red{
		background: var(--color-red);

		&:hover{
			background: var(--color-red);
		}
	}
}

.group{
	display: flex;
	width: 100%;
	box-sizing: border-box;
	flex-wrap: wrap;

	&.left{
		justify-content: flex-start;
	}

	&.right{
		justify-content: flex-end;
	}

	&.center{
		justify-content: center;
	}

	&.vertical{
		display: block;

		button{
			display: block;
			margin: 0 0 16px 0;
		}
	}

	&.sidebar{
		display: block;
		button {
			margin: 0;
		}
		button + button {
			margin: 8px 0 0 0;
		}
	}
}