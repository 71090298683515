@import "../../assets/styles/utils";


.news{
	padding: 12px 12px 16px;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 10px;
	cursor: pointer;
	overflow: hidden;

	@include min1180{
		padding: 20px 20px 34px;
	}
}

.media{
	display: block;
	margin-bottom: 12px;
	max-width: 100%;
	overflow: hidden;
}

.title{
	display: block;
	font-weight: 600;
	font-size: 24px;
	line-height: 1.33;
	color: #000;
	margin-bottom: 16px;

	&:hover{
		color: var(--color-link);
	}

	@include min1180{
		font-size: 32px;
		line-height: 1.25;
	}
}

.announce{
	display: block;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.62;
	color: #323232;
}