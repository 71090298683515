@import "../../assets/styles/utils";

.back{
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--color-light);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	cursor:pointer;
	border: none;
	outline: none;
	font-size: 14px;
	height:32px;
	line-height:1;
	color: var(--color-grey);
	@include padding(0, 16px, 0, 8px);

	@include min1180{
		@include padding(0, 0, 0, 0);
		width:32px;
	}
}

.icon{
	height: 18px;
	width: 18px;
	@include margin-right(8px);
	transform: rotate(0);

	html[dir="rtl"] &{
		transform: rotate(-180deg);
	}

	svg{
		fill: var(--color-grey);
	}

	@include min1180{
		@include margin(0,0,0,0);
		height:20px;
		width:20px;
	}
}