@import "../../assets/styles/utils";

.section{
	padding: 20px 0;

	&.private{
		background-color: var(--color-blue2);
		border-radius: 4px;
		padding: 20px;
		height: max-content;
	}
}

.controls{
	position: sticky;
	bottom: 0;
	background: var(--color-background);
	box-shadow: 0px -3px 10px rgba(3, 13, 35, 0.1);
	padding: 12px 24px;
	margin:0 -12px;
	z-index: 1;


	@include min1180{
		//display:flex;
		//justify-content: space-between;
		order: -1;
		margin: 0 20px;
		padding: 24px 0 15px;
		border-bottom: 1px solid #D3D3D3;
		box-shadow: none;
		position: sticky;
		top: 52px;
	}
}

.controlsColumn{
	@include min1180{
		display:flex;
		justify-content: space-between;
	}
}

.info{
	margin-bottom: 5px;
	flex: auto;
	width: 100%;
}

.title{
	color: #656565;
	font-size: 16px;
	margin-bottom: 5px;
}

.order{
	@include min1180{
		display: flex;
		flex-direction: column;
	}
}

.list{
	margin:0 0 32px 0;
}

.subTitle{
	font-weight: 500;
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
	margin-bottom:5px;
}


.buttons{
	button{
		width:100%;
	}

	button + button {
		margin: 16px 0 0 0;
	}

	@include min1180{
		display: flex;
		width: 100%;
		box-sizing: border-box;
		flex-wrap: wrap;
		justify-content: flex-end;

		button{
			width: auto;
		}

		button + button {
			@include margin(0, 0, 0, 8px);
		}
	}
}

.flex{
	@include min1180{
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.h1{
	font-weight: 500;
	font-size: 20px;
	line-height: 1.4;
	color: #000000;
	margin-bottom: 16px;
}

.link{
	margin-top: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	color: var(--color-link);
	transition: color $transition;
	height: max-content;

	&:hover{
		color: var(--color-dark);
	}
}


.field{
	margin-top: 20px;

	@include min1180{
		display: flex;
		width: 100%;
		gap: 16px;
		align-items: center;
	}
}

.fieldTitle{
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.5;
	color: #000000;

	@include min1180{
		margin: 0;
	}
}

.fieldInput{
	position: relative;
	flex: 1;

	label{
		margin: 0;
	}
}