@import "../../../assets/styles/utils";

.filter{

}

.title{
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	margin: 24px 0 8px;

	@include min1180{
		margin: 12px 0 8px;
	}
}

.filters{
	width:100%;
	margin: 16px 0 24px 0;

	@include min1180{
		display: flex;
		margin: 16px 0 8px 0;
	}
}

.filterItem{
	width:100%;
	margin: 0 0 0 0;

	@include min1180{
		width:250px;
		margin: 0 16px 0 0;
	}
}

.header{
	position:relative;
	background: var(--color-background);
}

.close{
	position: absolute;
	bottom: 0;
	width: 40px;
	height: 40px;
	@include right(0);
}

.arrow{
	position: absolute;
	bottom: 10px;
	width: 20px;
	height: 20px;
	@include right(10px);
}