@import '../../assets/styles/utils';

:global(.ScrollbarsCustom-Wrapper){
	@include min1180{
		@include inset(0, 10px, 0, 0);
	}
}

.container{
	height:100%;
	position:relative;
	@include margin(0, 0, 0, -6px);

	&.paddingTop{
		.inner {
			padding-top: 4px!important;
		}
	}
}

.inner{
	@include padding(0, 0, 0, 6px);

	@include min1180{
		@include padding(0, 6px, 0, 12px);
	}
}

.trackY{
	position: absolute;
	overflow: hidden;
	border-radius: 4px;
	background: rgba(0, 0, 0, 0.1);
	user-select: none;
	width: 7px;
	height: calc(100% - 10px);
	top: 5px;
	@include right(0);
}

.scrollUp{
	position: absolute;
	bottom: 8px;
	background: var(--color-blue2);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 2px 2px 3px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	width:32px;
	height:32px;
	cursor: pointer;
	z-index:111;
	padding:6px;
	pointer-events: none;
	opacity:0;
	transition: opacity .2s;
	@include right(25px);

	&.active{
		pointer-events: initial;
		opacity: 1;
	}
}

.count{
	position: absolute;
	top: -7px;
	background: var(--color-light);
	border-radius: 30px;
	height:16px;
	line-height: 16px;
	padding:0 5px;
	color:#323232;
	font-size:12px;
	@include right(18px);
}