.split {
	display: flex;
	flex-direction: row;
	height:100%;
	direction: ltr;

	* > {
		flex:auto;
		direction: initial;
	}
}

.gutter {
	position: relative;
	cursor: col-resize;
	overflow: hidden;

	&:after,
	&:before{
		content: '';
		position: absolute;
		width: 1px;
		left: 50%;
		margin-left: -.5px;
		background-color: #D3D3D3;
	}

	&:before{
		top: 0;
		bottom: calc(50% + 12.5px);
	}

	&:after{
		top: calc(50% + 12.5px);
		bottom: 0;
	}

	i{
		display: block;
		position: absolute;
		left: 50%;
		margin-left: -1px;
		width: 2px;
		height: 2px;
		border-radius: 50%;
		background: #545454;

		&:nth-child(1){
			top: 50%;
			margin-top: -8.5px;
		}

		&:nth-child(2){
			top: 50%;
			margin-top: -3.5px;
		}

		&:nth-child(3){
			top: 50%;
			margin-top: 1.5px;
		}

		&:nth-child(4){
			top: 50%;
			margin-top: 6.5px;
		}
	}
}