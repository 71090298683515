@import '../../../assets/styles/utils';

.close{
	position: absolute;
	width: 32px;
	height: 32px;
	cursor: pointer;
	top: 2px;
	box-sizing: border-box;
	@include right(2px);

	svg{
		width: 100%;
		height: 100%;
	}
}

.tab{
	background: var(--color-blue2);
}

.tabItem{
	height: 44px;
	line-height: 44px;
	padding: 0 20px;
	display: inline-block;
	vertical-align: middle;
	font-weight: 500;
	font-size: 16px;
	color: #4C4C4C;
	cursor: pointer;

	&.active{
		background-color: #fff;
		color: #000;
		cursor: auto;
	}
}

.content{
	overflow: hidden;
	background-color: #fff;
	padding: 20px 20px 16px;
}

.error{
	font-size: 16px;
	margin: 0 0 10px 0;
	color:#E74A3A;

	p{
		font-size: 14px;
		margin: 0 0 5px 0;
	}

	a{
		color: var(--color-link);
		text-decoration: none;
		transition: color $transition;

		&:hover{
			color: var(--color-dark);
		}
	}
}

.recovery{
	color: var(--color-grey);
	font-size: 14px;
	cursor: pointer;
	margin-bottom: 5px;
	&:hover{
		color: var(--color-blue);
	}
}

.footer{
	padding: 16px 20px;
	box-sizing: border-box;
	background: var(--color-blue2);
}

.beetwen{
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
}

.socialTitle{
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	white-space: nowrap;
	color: #4C4C4C;
	@include padding-right(15px);
}

.social{
	display: block;
	width: 32px;
	height: 32px;
	background: var(--color-blue2);

	&.vk{
		margin: -2px;
		width: 36px;
		height: 36px;
	}

	svg{
		width: 100%;
		height: 100%;
		fill:#fff!important;
	}
}

.text{
	color: #4C4C4C;
	font-size: 12px;
	line-height: 1.2;

	p{
		margin: 0 0 3px 0;
		padding: 0;
	}
}