@import "../../assets/styles/utils";
@import "../../assets/styles/common.module";

.auth{
	//background: #DCE4F0;
	//overflow: hidden;

	&.lottie{
		.headerWrapper,
		.page{
			max-width: 1260px;
		}
	}
	&.lottie{
		.header{
			background: #031755CC;
			backdrop-filter: blur(8px);
			z-index: 40;
			top: 0;
		}
	}
}

.header{
	position: sticky;
	background-color: var(--color-header);
}

.headerWrapper{
	height: 60px;
	padding: 0 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1440px;
}

.logo{
	width: 158px;
	@include margin-right(25px);
	box-sizing: content-box;
	transition: width $transition, padding $transition;

	svg{
		fill: #fff;
	}

	@include min1180{
		@include margin-right(100px);
	}

	&.hidden{
		opacity: 0;
		width: 0;
		margin: 0;
		visibility: hidden;
	}
}

.headerButton{
	display: flex;
	gap:0 8px;
}

.button{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 32px;
	line-height: 32px;
	padding: 0 16px;
	margin: 0;
	background: var(--color-blue);
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	transition: background-color $transition, color $transition;
	box-sizing: border-box;
	border: none;
	outline: none;
	color: #fff;
	min-width: auto;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;

	&.icon{
		padding: 0;
		width: 32px;
		height: 32px;
		background: var(--color-light);
		color: var(--color-dark);

		svg{
			fill: var(--color-dark);
		}
	}
}

.lang{
	display: flex;
}

.page{
	padding: 12px;
	min-height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
	max-width: 1440px;

	&.maxWidth{
		max-width: 1260px;
	}
}


.grid{
	@include  min1180 {
		display: grid;
		grid-template-columns: 1fr 380px;
		grid-gap: 10px;
	}
}

.list{
	margin: 10px 0;

	@include min1180 {
		margin: -12px 0 0;
	}
}