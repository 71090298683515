@import "../../../assets/styles/utils";


.item{
	position: relative;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 4px;
	@include padding(18px, 35px, 18px, 12px);
}

.header{
	display:grid;
	grid-template-columns: 48px 1fr;
}

.date{
	color: var(--color-grey);
	font-weight: 500;
	font-size: 14px;
	min-width:48px;
	box-sizing: border-box;
	white-space: nowrap;
	@include padding(6px, 8px, 0, 0);
}

.info{
	overflow:hidden;
}

.title{
	font-weight: 400;
	font-size: 20px;
	line-height: 1.4;
	color: #000000;
	cursor:pointer;

	&:hover{
		color: var(--color-link);
	}
}

.toggle{
	margin-top:4px;
	color: var(--color-link);
	font-size:12px;
	cursor:pointer;
}

.icon{
	display:inline-block;
	width:15px;
	position:relative;

	svg{
		position:absolute;
		top:1px;
		fill: var(--color-link);
	}
}

.body{
	@include min1180{
		@include padding-left(48px);
	}
}

.text{
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	color: #656565;
}