@import "../../../assets/styles/utils";


.date{
	display: block;
	white-space:nowrap;
	font-size: 14px;
	font-weight: 400;
	color: var(--color-grey);
}

.time{
	display: inline-block;
	color:#000;
	@include margin-right(4px);

	*[data-dir="rtl"] &{
		margin: 0 0 0 4px!important;
	}
}