@import "../../../assets/styles/utils";

.filter {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	background-color: var(--color-header);
	height: calc(100vh - 60px);
	overflow: visible;
	box-sizing: border-box;
	opacity: 0;
	visibility: hidden;
	transition: .2s ease transform, .2s ease opacity;
	z-index: 15;

	&.isOpen {
		visibility: visible;
		overflow: auto;
		opacity: 1;

		@include min1180 {
			overflow: visible;
		}
	}

	@include min1180 {
		height: auto;
		background: none;
	}
}

.wrapper{
	position: absolute;
	z-index: 123;
	background-color:var(--color-header);
	outline: none;
	padding: 0 12px 0;
	box-sizing: border-box;

	@include min1180{
		padding-left: 20px;
		padding-right: 20px;
		min-width:900px;
		max-height: calc(100vh - 120px);
		overflow: auto;
	}
}