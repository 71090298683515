@import "../../assets/styles/utils";

.icon{
	display: inline-block;
	width: 100%;
	height: 100%;
	margin: 0;

	svg{
		width: 100%;
		height: 100%;
		fill: #000;
		transition: fill $transition;
	}

	&.pointer{
		cursor: pointer;
	}
}