@import "../../assets/styles/utils";

.toolbar{
	display: flex;
	height: 24px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 0 12px;
	box-sizing: border-box;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 4px;
}

.title{
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
	color: #000;
}

.checkbox{
	label{
		margin: 0;
	}
}