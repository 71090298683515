@import "../../../assets/styles/utils";

.header{
	padding: 8px 0 12px;
	display: grid;
	width: 100%;
	grid-template-columns: 140px 120px 1fr 120px 40px;
}

.item{
	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
	color: var(--color-grey);
	padding: 0 12px;
}