$transition: .2s ease;
$blue: #44579E!default;
$border: 4px;
$datepicker__background-color: #fff !default;
$datepicker__border-color: #E6E6E6 !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #000 !default;
$datepicker__selected-color: #44579E !default;
$datepicker__text-color: #545454 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: #000 !default;

$datepicker__border-radius: 0px !default;
$datepicker__day-margin: 0 !default;
$datepicker__font-size: 14px !default;
$datepicker__item-size: 38px !default;
$datepicker__margin: 1px!default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;


$datepicker__range-color:#D0DAEF;

%navigation-chevron {
	border-color: $datepicker__text-color;
	border-style: solid;
	border-width: 2px 2px 0 0;
	content: "";
	display: block;
	height: 9px;
	position: absolute;
	top: 6px;
	width: 9px;

	&--disabled,
	&--disabled:hover {
		border-color: $datepicker__muted-color;
		cursor: default;
	}
}

%triangle-arrow {
	margin-left: -$datepicker__triangle-size * 0.5;
	position: absolute;
	width: 0;

	&::before,
	&::after {
		box-sizing: content-box;
		position: absolute;
		border: $datepicker__triangle-size solid transparent;
		height: 0;
		width: 1px;
		content: "";
		z-index: -1;
		border-width: $datepicker__triangle-size;
		left: -$datepicker__triangle-size;
	}

	&::before {
		border-bottom-color: $datepicker__border-color;
	}
}

%triangle-arrow-up {
	@extend %triangle-arrow;

	top: 0;
	margin-top: -$datepicker__triangle-size;

	&::before,
	&::after {
		border-top: none;
		border-bottom-color: $datepicker__background-color;
	}

	&::after {
		top: 0;
	}

	&::before {
		top: -1px;
		border-bottom-color: $datepicker__border-color;
	}
}

%triangle-arrow-down {
	@extend %triangle-arrow;

	bottom: 0;
	margin-bottom: -$datepicker__triangle-size;

	&::before,
	&::after {
		border-bottom: none;
		border-top-color: #fff;
	}

	&::after {
		bottom: 0;
	}

	&::before {
		bottom: -1px;
		border-top-color: $datepicker__border-color;
	}
}

.react-datepicker-wrapper{
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
}

.react-datepicker__input{
	background: #FFFFFF;
	border: 1px solid #D9E3F7;
	box-sizing: border-box;
	border-radius: 4px;
	color: #000;
	display: block;
	width: 100%;
	height: 30px;
	line-height: 30px;
	box-sizing: border-box;
	outline: none;
	padding: 0 30px 0 12px;
	transition: border-color $transition;

	&.react-datepicker-ignore-onclickoutside{
		border-color: var(--color-blue);
	}
}

.react-datepicker-popper{
	z-index: 1;

	&[data-placement^=top] {
		padding-bottom: 10px;
	}
}

.react-datepicker{
	font-size: 14px;
	background-color: #fff;
	color: #000;
	border-radius: 4px;
	display: inline-block;
	//position: relative;
	user-select: none;
	white-space: nowrap;
	padding-bottom: 30px;
}

.react-datepicker--time-only {
	.react-datepicker__triangle {
		left: 35px;
	}

	.react-datepicker__time-container {
		border-left: 0;
	}

	.react-datepicker__time,
	.react-datepicker__time-box {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
}

.react-datepicker__triangle {
	position: absolute;
	left: 50px;
}

.react-datepicker-popper {
	z-index: 1;

	&[data-placement^="bottom"] {
		padding-top: $datepicker__triangle-size + 2px;

		.react-datepicker__triangle {
			@extend %triangle-arrow-up;
		}
	}

	&[data-placement="bottom-end"],
	&[data-placement="top-end"] {
		.react-datepicker__triangle {
			left: auto;
			right: 50px;
		}
	}

	&[data-placement^="top"] {
		padding-bottom: $datepicker__triangle-size + 2px;

		.react-datepicker__triangle {
			@extend %triangle-arrow-down;
		}
	}

	&[data-placement^="right"] {
		padding-left: $datepicker__triangle-size;

		.react-datepicker__triangle {
			left: auto;
			right: 42px;
		}
	}

	&[data-placement^="left"] {
		padding-right: $datepicker__triangle-size;

		.react-datepicker__triangle {
			left: 42px;
			right: auto;
		}
	}
}

.react-datepicker__header {
	text-align: center;
	//background-color: $datepicker__background-color;
	//border-bottom: 1px solid $datepicker__border-color;
	//border-top-left-radius: $datepicker__border-radius;
	padding: 8px 0;

	&--time {
		padding-bottom: 8px;
		padding-left: 5px;
		padding-right: 5px;

		//&:not(&--only) {
		//	border-top-left-radius: 0;
		//}
	}

	//&:not(&--has-time-select) {
	//	border-top-right-radius: $datepicker__border-radius;
	//}
	@media (min-width: 767px) {
		position: relative;
	}
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
	display: inline-block;
	margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	color: $datepicker__header-color;
	font-weight: bold;
	font-size: $datepicker__font-size;
}

.react-datepicker-time__header {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.react-datepicker__navigation {
	align-items: center;
	background: none;
	display: flex;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 2px;
	padding: 0;
	border: none;
	z-index: 1;
	height: $datepicker__navigation-button-size;
	width: $datepicker__navigation-button-size;
	text-indent: -999em;
	overflow: hidden;

	&--previous {
		left: 2px;

		&.react-datepicker__navigation-rtl{
			left: auto;
			right: 2px;
			transform: rotate(-180deg);
		}
	}

	&--next {
		right: 2px;

		&--with-time:not(&--with-today-button) {
			right: 85px;
		}

		&.react-datepicker__navigation-rtl{
			right: auto;
			left:2px;
			transform: rotate(-180deg);
		}
	}

	&--years {
		position: relative;
		top: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;

		&-previous {
			top: 4px;
		}

		&-upcoming {
			top: -4px;
		}
	}
}

.react-datepicker__navigation-icon {
	position: relative;
	top: -1px;
	font-size: 20px;
	width: 0;

	&::before {
		@extend %navigation-chevron;
	}

	&--next {
		left: -2px;

		&::before {
			transform: rotate(45deg);
			left: -7px;
		}
	}

	&--previous {
		right: -2px;

		&::before {
			transform: rotate(225deg);
			right: -7px;
		}
	}
}

.react-datepicker__month-container {
	display: inline-block;
	vertical-align: top;
	& + &{
		margin-left: 22px;
	}
}

.react-datepicker__year {
	margin: $datepicker__margin;
	text-align: center;

	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		max-width: 180px;
	}

	.react-datepicker__year-text {
		display: inline-block;
		width: 4rem;
		margin: 2px;
	}
}

.react-datepicker__month {
	margin: $datepicker__margin;
	text-align: center;

	.react-datepicker__month-text,
	.react-datepicker__quarter-text {
		display: inline-block;
		width: 4rem;
		margin: 2px;
	}
}

.react-datepicker__input-time-container {
	clear: both;
	width: 100%;
	float: left;
	margin: 5px 0 10px 15px;
	text-align: left;

	.react-datepicker-time__caption {
		display: inline-block;
	}

	.react-datepicker-time__input-container {
		display: inline-block;

		.react-datepicker-time__input {
			display: inline-block;
			margin-left: 10px;

			input {
				width: auto;
			}

			input[type="time"]::-webkit-inner-spin-button,
			input[type="time"]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[type="time"] {
				-moz-appearance: textfield;
			}
		}

		.react-datepicker-time__delimiter {
			margin-left: 5px;
			display: inline-block;
		}
	}
}

.react-datepicker__time-container {
	float: right;
	border-left: 1px solid $datepicker__border-color;
	width: 85px;

	&--with-today-button {
		display: inline;
		border: 1px solid #aeaeae;
		border-radius: 0.3rem;
		position: absolute;
		right: -72px;
		top: 0;
	}

	.react-datepicker__time {
		position: relative;
		background: white;
		border-bottom-right-radius: 0.3rem;

		.react-datepicker__time-box {
			width: 85px;
			overflow-x: hidden;
			margin: 0 auto;
			text-align: center;
			border-bottom-right-radius: 0.3rem;

			ul.react-datepicker__time-list {
				list-style: none;
				margin: 0;
				height: calc(195px + (#{$datepicker__item-size} / 2));
				overflow-y: scroll;
				padding-right: 0;
				padding-left: 0;
				width: 100%;
				box-sizing: content-box;

				li.react-datepicker__time-list-item {
					height: 30px;
					padding: 5px 10px;
					white-space: nowrap;

					&:hover {
						cursor: pointer;
						background-color: $datepicker__background-color;
					}

					&--selected {
						background-color: var(--color-blue);
						color: white;
						font-weight: bold;

						&:hover {
							background-color: var(--color-blue);
						}
					}

					&--disabled {
						color: $datepicker__muted-color;

						&:hover {
							cursor: default;
							background-color: transparent;
						}
					}
				}
			}
		}
	}
}

.react-datepicker__week-number {
	color: $datepicker__muted-color;
	display: inline-block;
	width: $datepicker__item-size;
	line-height: $datepicker__item-size;
	text-align: center;
	margin: $datepicker__day-margin;

	&.react-datepicker__week-number--clickable {
		cursor: pointer;

		&:hover {
			border-radius: $datepicker__border-radius;
			background-color: $datepicker__background-color;
		}
	}
}

.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}

.react-datepicker__day-names {
	margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: $datepicker__text-color;
	display: inline-block;
	width: $datepicker__item-size;
	line-height: $datepicker__item-size;
	text-align: center;
	margin: $datepicker__day-margin;
}

.react-datepicker__day{
	border-top:1px solid transparent;
	border-bottom:1px solid transparent;

	&.day-1000:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month){
		background-color: var(--color-day-1000);
	}

	&.day-500:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month){
		background-color: var(--color-day-500);
	}

	&.day-350:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month){
		background-color: var(--color-day-3500);
	}

	&.day-250:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month){
		background-color: var(--color-day-2500);
	}

	&.day-0:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month){
		background-color: var(--color-day-0);
	}

	&.react-datepicker__day--range-start,
	&.react-datepicker__day--range-end {
		background-color: var(--color-blue)!important;
		color: #fff!important;

		&:hover {
			//background-color: darken(var(--color-blue), 5%);
			background-color: var(--color-blue);
		}
	}

	&.react-datepicker__day--range-start,
	&.react-datepicker__day--selected.react-datepicker__day--range-start{
		border-radius: 3px 0 0 3px!important;
	}
	&.react-datepicker__day--range-end,
	&.react-datepicker__day--selected.react-datepicker__day--range-end{
		border-radius: 0 3px 3px 0!important;
	}
	&.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start{
		border-radius: 3px!important;
	}
	&.react-datepicker__day--selecting-range-start{
		background-color: var(--color-blue)!important;
		color:#fff!important;
	}

	&.react-datepicker__day--outside-month{
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
	}
}

.react-datepicker__month,
.react-datepicker__quarter {
	&--selected,
	&--in-selecting-range,
	&--in-range {
		border-radius: $datepicker__border-radius!important;
		background-color: var(--color-blue)!important;
		color: #fff!important;

		&:hover {
			//background-color: darken(var(--color-blue), 5%) !important;
			background-color: var(--color-blue);
		}
	}

	&--disabled {
		color: $datepicker__muted-color!important;
		pointer-events: none;

		&:hover {
			cursor: default;
			background-color: transparent;
		}
	}
}


.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
	cursor: pointer;
	position: relative;
	margin: -1px 0 0 0;

	//span{
	//	position: absolute;
	//	top:50%;
	//	left:50%;
	//	width: $datepicker__item-size;
	//	height: $datepicker__item-size;
	//	line-height: $datepicker__item-size;
	//	transform: translate(-50%, -50%);
	//}

	&:hover {
		border-radius: 3px;
		background-color: var(--color-blue)!important;
		color: #fff!important;
	}

	&--today {
		font-weight: bold;
	}

	&--highlighted {
		border-radius: $datepicker__border-radius;
		background-color: $datepicker__highlighted-color;
		color: #fff;

		&:hover {
			background-color: darken($datepicker__highlighted-color, 5%);
		}

		&-custom-1 {
			color: magenta;
		}

		&-custom-2 {
			color: green;
		}
	}

	&--selected,
	&--in-selecting-range{
		border-radius: var(--color-blue);
		//background-color: $datepicker__selected-color;
		border-top-color: var(--color-blue);
		border-bottom-color: var(--color-blue);
		border-radius: 0;
		//color: #fff;

		&:hover {
			//background-color: darken(var(--color-blue), 5%);
			background-color: var(--color-blue);
		}


		&:first-child{
			border-left: 1px solid var(--color-blue);
			border-radius: 3px 0 0 3px;
		}

		&:last-of-type,
		&:hover{
			border-right:1px solid var(--color-blue);
			border-radius: 0 3px 3px 0!important;
		}
	}


	&--in-selecting-range:not(&--selected, &--range-end, &--range-start),
	&--in-range:not(&--selected, &--range-end, &--range-start){
		//background-color: $datepicker__range-color!important;
		//color: #000!important;
		//background-color: rgba($datepicker__selected-color, 0.5);
		border-top-color: var(--color-blue);
		border-bottom-color: var(--color-blue);
	}

	&--keyboard-selected{
		//border-radius: $datepicker__border-radius;
		//background-color: $datepicker__selected-color;
		//color: #fff;

		//&:hover {
		//	background-color: darken($datepicker__selected-color, 5%);
		//}
	}

	&--in-range:not(&--in-selecting-range) {
		.react-datepicker__month--selecting-range & {
			background-color: $datepicker__background-color;
			color: $datepicker__text-color;
		}
	}

	&--disabled {
		cursor: default;
		color: $datepicker__muted-color;
		opacity: 0.5;
		background-color: transparent!important;

		&:hover {
			background-color: transparent!important;
			color: $datepicker__muted-color!important;
		}
	}

	&--in-range.react-datepicker__day--outside-month,
	&--in-selecting-range.react-datepicker__day--outside-month{
		border-color: transparent!important;
		background-color: transparent!important;
	}
}

.react-datepicker__day--today{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -2px;
		bottom: 5px;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background: #0EB88F;
	}
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
	&.react-datepicker__month--selected,
	&.react-datepicker__month--in-range,
	&.react-datepicker__quarter--selected,
	&.react-datepicker__quarter--in-range {
		&:hover {
			background-color: var(--color-blue);
		}
	}

	&:hover {
		background-color: $datepicker__background-color;
	}
}

.react-datepicker__input-container {
	position: relative;
	display: inline-block;
	width: 100%;

	&:after{
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		cursor: pointer;
		top: 50%;
		margin-top: -10px;
		right: 9px;
		background-image: url("../../assets/svg/calendar.svg");
	}

	input{
		display: block;
		width: 100%;
		height: 38px;
		line-height: 38px;
		background: transparent;
		border: 1px solid #5F72AF;
		border-radius: 4px;
		outline: none;
		color: #fff;
		padding: 0 12px;
		fill:#fff;
	}
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
	border: 1px solid transparent;
	border-radius: $datepicker__border-radius;
	position: relative;

	&:hover {
		cursor: pointer;

		.react-datepicker__year-read-view--down-arrow,
		.react-datepicker__month-read-view--down-arrow {
			border-top-color: darken($datepicker__muted-color, 10%);
		}
	}

	&--down-arrow {
		@extend %navigation-chevron;

		transform: rotate(135deg);
		right: -16px;
		top: 0;
	}
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	background-color: $datepicker__background-color;
	position: absolute;
	width: 50%;
	left: 25%;
	top: 30px;
	z-index: 1;
	text-align: center;
	border-radius: $datepicker__border-radius;
	border: 1px solid $datepicker__border-color;

	&:hover {
		cursor: pointer;
	}

	&--scrollable {
		height: 150px;
		overflow-y: scroll;
	}
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
	line-height: 20px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;

	&:first-of-type {
		border-top-left-radius: $datepicker__border-radius;
		border-top-right-radius: $datepicker__border-radius;
	}

	&:last-of-type {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		border-bottom-left-radius: $datepicker__border-radius;
		border-bottom-right-radius: $datepicker__border-radius;
	}

	&:hover {
		background-color: $datepicker__muted-color;

		.react-datepicker__navigation--years-upcoming {
			border-bottom-color: darken($datepicker__muted-color, 10%);
		}

		.react-datepicker__navigation--years-previous {
			border-top-color: darken($datepicker__muted-color, 10%);
		}
	}

	&--selected {
		position: absolute;
		left: 15px;
	}
}

.react-datepicker__close-icon {
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: 0;
	padding: 0 6px 0 0;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: table-cell;
	vertical-align: middle;

	&::after {
		cursor: pointer;
		background-color: var(--color-blue);
		color: #fff;
		border-radius: 50%;
		height: 16px;
		width: 16px;
		padding: 2px;
		font-size: 12px;
		line-height: 1;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
		content: "\00d7";
	}
}

.react-datepicker__today-button {
	background: $datepicker__background-color;
	border-top: 1px solid $datepicker__border-color;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	padding: 5px 0;
	clear: left;
}

.react-datepicker__portal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
	left: 0;
	top: 0;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 2147483647;

	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 3rem;
		line-height: 3rem;
	}

	@media (max-width: 400px), (max-height: 550px) {
		.react-datepicker__day-name,
		.react-datepicker__day,
		.react-datepicker__time-name {
			width: 2rem;
			line-height: 2rem;
		}
	}

	.react-datepicker__current-month,
	.react-datepicker-time__header {
		font-size: $datepicker__font-size * 1.8;
	}
}



//DROPDOWN
.react-datepicker__dropdown{
	position: relative;
	display: inline-block;
	margin: 0 3px;
}

.react-datepicker__dropdown-value{
	display: flex;
	align-content: center;
	justify-content: center;
	font-weight: 700;
	font-size: 14px;
	color: #000000;
	line-height: 20px;
	white-space: nowrap;
	cursor: pointer;

	&.react-datepicker__dropdown-grey{
		font-weight: 500;
		color: #B8B8B8;
	}
}

.react-datepicker__dropdown-icon{
	display: inline-block;
	position: relative;
	width: 16px;
	height: 20px;
	margin-left: 3px;

	span{
		position: absolute;
		left:0;
		width: 100%;
		top:0;
		height: 100%;
	}

	svg{
		fill:#B8B8B8!important;
	}
}

.react-datepicker__dropdown-body{
	position: absolute;
	left:0;
	top: -50px;
	max-height: 250px;
	overflow: auto;
	background: var(--color-blue2);
	box-shadow: 2px 4px 12px rgba(95, 114, 175, 0.12);
	border-radius: 4px;
	z-index: 10;
}

.react-datepicker__dropdown-item{
	white-space: nowrap;
	font-weight: 400;
	font-size: 12px;
	line-height: 1;
	padding: 4px 12px;
	text-align: left;
	cursor: pointer;
	color: #656565;
	transition: color .2s ease, background-color .2s ease;
	min-width: 70px;


	&:hover{
		background: var(--color-light);
		border-radius: 2px;
		color:#000;
	}
}