@import "../../assets/styles/utils";

.tags{
	display: flex;
	flex-wrap: wrap;
}

.tag{
	height:24px;
	line-height:24px;
	background: var(--color-blue2);
	border-radius: 4px;
	padding: 0 8px;
	color: var(--color-grey);
	transition: background-color $transition, color $transition;
	@include margin(0, 4px, 4px, 0);

	&.click{
		cursor: pointer;

		&:hover{
			background-color: var(--color-link);
			color: #fff;
		}
	}

	*[data-dir="rtl"] &{
		margin: 0 0 4px 4px!important;
	}
}

.link{
	display:inline-block;
	height:32px;
	line-height:32px;
	background: var(--color-blue2);
	border-radius: 4px;
	padding: 0 12px;
	color: var(--color-grey);
	transition: background-color $transition, color $transition;
	cursor: pointer;
	@include margin(0, 4px, 4px, 0);

	&:hover{
		background-color: var(--color-link);
		color: #fff;
	}

	&.blue{
		color: #fff;
		background-color: var(--color-blue);

		&:hover{
			background-color: var(--color-link);
			color: #fff;
		}
	}
}