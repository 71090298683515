@import "../../../assets/styles/utils";


.label{
	display: flex;
	align-items: flex-start;
	cursor: pointer;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 16px;

	&:hover {
		.title{
			color:#000;
		}
	}

	&.checked{
		.toggle{
			background: var(--color-light2);
		}
		.icon{
			background-color: var(--color-link);
			left:12px;
		}
	}

	&.start{
		flex-direction: row-reverse;

		.title{
			@include padding(0, 6px, 0, 0);
		}
	}

	&.light{
		//.icon{
		//	background-color: var(--color-link);
		//}
		.title{
			color: #fff;
		}
	}
}

.toggle{
	position: relative;
	top:2px;
	width: 23px;
	height:12px;
	border-radius: 40px;
	background: #7884AB;
	overflow:hidden;
	transition: background $transition;
}

.title{
	font-weight: 400;
	font-size: 14px;
	color: var(--color-grey);
	flex: 1;
	@include padding(0, 0, 0, 6px);

	a {
		color:#000;
	}
}

.input{
	display:none;
	position:absolute;
}

.icon{
	width:10px;
	height:10px;
	position:absolute;
	background: #344371;
	top:1px;
	border-radius: 50%;
	transition: left $transition;
	@include left(1px);
}