@import "../../assets/styles/utils";

.modal{
	position: relative;
	background: #FFFFFF;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	padding: 60px 20px 20px;
	width: 320px;
	//max-width: calc(100% - 20px);
	margin:0;
	//max-width: 100%;
	box-sizing:border-box;


	@include min1180{
		width: 430px;
	}
}

.tourIndex{
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	color: var(--color-grey);
	position:absolute;
	top:23px;
	left:20px;

	span{
		color:#000;
	}
}

.text{
	font-weight: 400;
	font-size: 16px;
	line-height: 1.62;
	color: #000000;
	margin-bottom:24px;
}

.close{
	width: 50px;
	height: 50px;
	position: absolute;
	top:12px;
	right: 7px;
	cursor: pointer;
}