@import "../../../assets/styles/utils";

.lenta{
  height: 335px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 50px 1fr 40px;

  @include min1180{
    grid-template-rows: 50px 1fr;
  }
}

.list{
  overflow: hidden;
}

.header{
  position: relative;
  z-index: 10;
  //background: var(--color-background);
  padding-bottom: 4px;
  margin-bottom: -4px;
}

.headerWrapper{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
  padding: 9px 12px;
  align-items: center;
  border-radius: 4px 4px 0 0;
  background: var(--color-main);
}

.headerIcon{
  height: 32px;
  width: 32px;
  svg{
    fill: #fff;
    width: 100%;
    height: 100%;
  }
}

.headerTitle{
  color: #fff;
  flex:1;
  font-size: 18px;
  font-weight: 500;
}

.footer{
  box-shadow: 0px -3px 10px 0px #030D231A;

  @include min1180{
    display: none;
  }
}

.footerLink{
  font-size: 14px;
  display: block;
  text-align: center;
  padding: 12px 20px 12px 20px;
  color: var(--color-link);

  &:hover{
    color: var(--color-dark);
  }
}