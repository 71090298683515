@import "../../assets/styles/utils";

.subcribe{
	margin:0 0 4px 0;
	width:100%;
	display:flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--color-subscribe);
	background-image: url(../../assets/images/bannerSubscribe.png);
	background-position: bottom right;
	background-repeat: no-repeat;
	@include padding(5px, 15px, 5px, 20px);

	&.sputnik{
		background: #333333;
	}
}

.text{
	font-weight: 500;
	font-size: 20px;
	color:#fff;
}

.icon{
	width:40px;
	height:40px;

	svg{
		fill:#fff;
	}
}