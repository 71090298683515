@import "../../assets/styles/utils";

.print{
	position:relative;
	width:1000px;
	margin:0 auto;
}

.header{
	padding:50px 0 20px;
	border-bottom: 1px solid #e7e7e7;
}

.logo{
	display:inline-block;
	//width:350px;
	height:32px;
	outline: none;
	border:none;

	svg{
		height: 100%;
		width: auto;
	}
}

.content{

}

.article{
	padding: 63px 0 10px;
}

.date{
	font-family: 'Golos', sans-serif;
	font-size: 12px;
	color: #999;
}

.keywords{
	font-family: 'Golos', sans-serif;
	font-size: 10px;
	color: #999;
	margin:0 0 36px 0;
}

.media{
	display: block;
	margin: 16px 0 15px;
	position:relative;
	width: 100%;
	height: auto;
	max-height: 600px;

	img{
		height: auto;
		max-height: 100%;
		max-width:100%;
		width: auto;
		display: block;
		margin: 0 auto;
	}
}

.title{
	margin: 8px 0 15px;
	font-weight: normal;
	font-style: normal;
	font-family: 'Golos', sans-serif;
	line-height: 1.2;
}

.body{
	font-family: 'Golos', sans-serif;
	color:#000;
	padding:0;
	margin: 0;

	p{
		margin:0 0 15px 0;
		padding:0;
	}

	a{
		color: var(--color-link);
		text-decoration: underline;
	}
}

.footer{
	padding: 20px 0;
	color: #666;
	font-size: 14px;
	font-family: 'Golos', sans-serif;

	p{
		margin:0 0 5px 0;
		padding:0;
	}

	a{
		color: var(--color-link);
	}
}