@import "../../assets/styles/utils";

.container{
	position: relative;
}

.head{
	position: relative;
}

.dropdown{
	position:fixed;
	opacity:0;
	transform: translateY(10px);
	visibility: hidden;
	background: #fff;
	z-index:1001;
	width:100%;
	height:100%;
	transition: transform .3s ease, opacity .3s ease;

	&.isOpen{
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
	}

	@include min1180{
		width: 622px;
		height: 630px;
		margin-top:3px;
		box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 10px 15px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
	}

	&.smHeight{
		@include min1180{
			height:575px;
		}
	}
}

.title{
	font-weight: 500;
	font-size: 18px;
	line-height: 1.44;
	margin-bottom: 24px;
}

.close{
	position:absolute;
	top: 6px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	@include right(4px);

	svg{
		fill: var(--color-grey);
	}

	&:hover{
		svg{
			fill: #000;
		}
	}
}

.header{
	padding: 12px 12px;

	@include min1180{
		padding: 12px 20px;
	}
}

.range{

}

.slide{
	padding:20px 25px 50px;
	direction: ltr;

	svg{
		fill:#fff!important;
	}
}

.subtitle{
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 14px;
	line-height: 1.29;
	color: #000000;
	padding:0 12px;

	@include min1180{
		padding: 0 20px;
	}
}


.wrapper{
	min-height: 340px;
	text-align: center;
	position: relative;

	@include min1180{
		padding: 0 18px;
	}
}

.footer{
	padding:16px 12px 24px;
	border-top:1px solid var(--color-light2);
	color: var(--color-grey);

	@include min1180{
		display:flex;
		padding:16px 17px 24px;
	}
}

.tag{
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 16px;

	@include min1180{
		@include margin-right(48px);
	}
}

.info{
	display:flex;
	flex-wrap:wrap;
}

.item{
	height:26px;
	line-height:26px;
	padding:0 6px;
	background: var(--color-background);
	color: #656565;
	font-weight: 400;
	font-size: 12px;
	border-radius:3px;
}

.controls{
	margin-top:20px;
	display:inline-block;
	white-space:nowrap;
}

.fields{
	max-width: 440px;
}

.save{
	display: flex;
	align-items: flex-end;
	width: 100%;
	justify-content: flex-end;
	padding: 0 12px;

	button{
		width:100%;
	}

	@include min1180{
		position:absolute;
		bottom:0;
		padding: 0 20px 20px;
		@include left(0);

		button{
			width: auto;
		}
	}
}