@import "../../../assets/styles/utils";

.label{
	flex: 1;
	display: block;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 16px;

	&.focus{
		.title{
			top: -7px;
			font-size: 12px;
		}
	}

	&.hasValue{
		.title {
			top: -7px;
			font-size: 12px;
		}
	}

	&.hasIcon{
		.input {
			@include padding(0, 30px, 0, 12px);
		}
	}

	&.disabled{
		cursor: inherit;
		opacity: 0.6;
	}

	&.blue{
		.input{
			height: 40px;
			line-height: 40px;
			border-color: var(--color-input);
			color: #000;
		}

		.title{
			position: static;
			margin-bottom: 5px;
			font-size: 12px;
			color: var(--color-grey);
			background: transparent;
		}

		//.icon{
		//	top: 12px;
		//}
	}

	&.light{
		.input{
			height: 40px;
			line-height: 40px;
			border-color: var(--color-border);
			color: #fff;
		}

		.title{
			position: static;
			margin-bottom: 5px;
			font-size: 12px;
			color: #DBDEE9;
			background: transparent;
		}

		//.icon{
		//	top: 12px;
		//}
	}

	&.grey{
		.input{
			height: 40px;
			line-height: 40px;
			border-color: var(--color-input);
			color: #000;
		}

		.title{
			position: static;
			margin-bottom: 5px;
			font-size: 12px;
			color: var(--color-grey);
			background: transparent;
		}

		//.icon{
		//	top: 12px;
		//}
	}


	&.hasError{
		.input{
			border-color: var(--color-red);
			color: var(--color-red);
		}
	}
}

.container{
	position: relative;
}

.title{
	font-weight: 400;
	font-size: 14px;
	color: var(--color-grey);
	position: absolute;
	top: 7px;
	background-color: #fff;
	transition: all $transition;
	@include left(13px);
	z-index: 1;
}

.input{
	display: block;
	width: 100%;
	padding: 0 12px;
	font-size: 14px;
	font-weight: 400;
	color: #000;
	background: none;
	outline: none;
	box-sizing: border-box;
	border: 1px solid  var(--color-input);
	border-radius: 4px;
	height: 30px;
	line-height: 30px;
}

.icon{
	height: 16px;
	width: 16px;
	position: absolute;
	top: 8px;
	@include right(12px);
}

.alert{
	position: absolute;
	top: -8px;
	@include right(-6px);
}

.alertIcon{
	height: 16px;
	width: 16px;
	cursor: pointer;
	svg{
		fill: var(--color-red);
	}
}

.error{
	font-size: 14px;
	color: var(--color-red);
	position: absolute;
	top: 100%;
	@include left(0);
	margin-top: 5px;
}