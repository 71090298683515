@import "../../../assets/styles/utils";

.item{
	position: relative;
	@include padding(12px, 12px, 35px, 60px);
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 4px;
	//min-height: 95px;
	border: 1px solid #fff;
	box-sizing: border-box;
	overflow: hidden;
	transition: border-color $transition;

	@include min1180{
		@include padding(12px, 60px, 12px, 60px);
	}

	&:hover{
		.checkbox,
		.pin{
			opacity: 1;
		}
	}


	&.main{
		border-color: var(--color-blue);
	}

	&.isRead{
		//opacity: .4;
		.date{
			color: #6F4FA4;
		}
		.title{
			color: #6F4FA4;
			//
			//&:hover{
			//	color:#000;
			//}
		}
	}

	&.priority-1{
		&.isRead{
			.title{
				color: #6F4FA4;
			}
		}
		.title{
			color:#D25245;

			&:hover{
				color:#D25245;
			}
		}
	}

	&.priority-2{
		&.isRead{
			.title{
				color: #6F4FA4;
			}
		}
		.title{
			color:#059E79;

			&:hover{
				color:#059E79;
			}
		}
	}

	&.disabled{
		opacity: .4;

		//.checkbox,
		//.pin,
		//.favorite,
		//.controls{
		//	visibility: hidden;
		//	opacity: 0;
		//}
	}

	&.compact{
		@include padding(4px, 4px, 4px, 60px);

		&:hover{
			.slider{
				transform: translateX(0)!important;
			}
		}

		.content{
			min-height: 48px;
		}

		.announce{
			margin-top: 2px;
		}

		.pin{
			top: 28px;
			bottom: initial;
			@include left(30px);
		}

		.favorite{
			top: 28px;
		}

		.date{
			top: 6px;
		}
		.controls{
			position: static;
		}

		.checkbox{
			position: static;
			@include margin-left(12px);
		}

		.slider{
			position: absolute;
			display: flex;
			align-items: center;
			top: 0;
			@include right(0);
			bottom: 0;
			@include padding(0, 13px, 0, 85px);
			transition: transform $transition;

			html[dir="rtl"] &{
				background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, #FFFFFF 69.79%, #FFFFFF 100%);
				transform: translateX(-100%);
			}

			html[dir="ltr"] &{
				background: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 69.79%, rgba(255, 255, 255, 0.2) 100%);
				transform: translateX(100%);
			}

			&.checked{
				transform: translateX(0)!important;
			}
		}
	}

	&.widget{
		.title {
			font-size: 14px;
		}
	}
}

.date{
	position: absolute;
	top: 13px;
	@include left(12px);
	font-weight: 500;
	font-size: 14px;
	line-height: 1.43;
	color: var(--color-grey);
}

.content{
	min-height: 70px;
	display: flex;
	width: 100%;
	overflow: hidden;
	flex-direction: column;
	justify-content: space-between;
	@include padding-right(30px);

	@include min1180{
		@include padding-right(0);
	}
}

.text{
	cursor: pointer;

	&:hover{
		.title{
			color: var(--color-link);
		}

		.leadText{
			color: var(--color-grey);
		}
	}
}

.title{
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
	transition: color $transition;
	cursor: pointer;

	span{
		@include padding(0, 6px, 0, 0);
	}
}

.external{
	display: inline-block;
	@include margin(0, 6px,0, 0);
	height: 16px;
	line-height: 16px;
	padding: 0 5px;
	background: var(--color-light);
	color: var(--color-grey);
	font-size: 12px;
	border-radius: 30px;

	&.main{
		height: 20px;
		line-height: 19px;
		border-radius: 20px;
		padding: 0 8px;
		background: var(--color-blue);
		color: #fff;
		box-sizing: border-box;
	}
}

.announce{
	margin-top: 8px;
	min-height: 14px;
}

.leadToggle{
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.33;
	color: var(--color-link);
	margin-bottom: 4px;
	cursor: pointer;

	&:hover{
		color: var(--color-dark);

		.leadIcon{
			svg{
				fill: var(--color-dark);
			}
		}
	}
}

.leadIcon{
	display: inline-block;
	width: 14px;
	height: 14px;
	line-height: 1;
	@include margin-left(2px);

	svg{
		fill:var(--color-link);
	}
}

.leadText{
	margin-top: 4px;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	color: #656565;
}

.pin{
	position: absolute;
	bottom: 12px;
	@include left(12px);
	width: 24px;
	height: 24px;
	transition: opacity $transition;

	@include min1180{
		opacity: 0;
	}

	svg{
		fill: var(--color-grey);
	}

	&:hover{
		svg{
			fill: var(--color-link);
		}
	}

	&.pined{
		opacity: 1;
		svg{
			fill: var(--color-grey);
		}
	}
}

.favorite{
	position: absolute;
	top: 35px;
	@include left(12px);
	width: 16px;
	height: 24px;

	svg{
		fill: var(--color-link);
	}
}

.annotate{
	//position: absolute;
	//bottom: 8px;
	//@include left(56px);
	//@include right(150px);
	display: none;
	width: 100%;
	align-items: center;
	overflow: hidden;
	line-height: 1;
	margin-top: 8px;

	@include min1180{
		display:flex;
	}
}

.keyword{
	position:relative;
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 50%;
	overflow: hidden;
	color: var(--color-keywords);
	@include margin-right(5px);
}

.keywordItem{
	font-weight: 400;
	font-size: 12px;
	line-height: 1;
	text-transform: uppercase;
	color: var(--color-keywords);
	display:inline;
	@include padding(0, 5px, 0, 0);

	&:before{
		content: '/ ';
	}

	&:first-child{
		&:before{
			content: none;
		}
	}
}

.media{
	position:relative;
	font-weight: 400;
	font-size: 12px;
	line-height: 25px;
	color: #656565;
	white-space:nowrap;
	@include padding(0, 0, 0, 13px);

	&:after{
		content: '';
		display: block;
		position:absolute;
		top:5px;
		@include left(0);
		width: 1px;
		height: 16px;
		background: var(--color-light2);
	}
}

.checkbox{
	position: absolute;
	@include right(12px);
	top: 18px;
	transition: opacity $transition;

	@include min1180{
		opacity: 0;
	}

	&.checked{
		opacity: 1;
	}

	label{
		margin: 0;
	}
}

.controls{
	position: absolute;
	@include right(2px);
	bottom: 6px;
	display: flex;
	align-items: center;
	transition: transform $transition;

	> * {
		flex: 1;
	}
}


.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	cursor: pointer;
	border-radius: 50%;
	transition: background-color $transition;
	@include margin(0, 0, 0, 8px);

	&:hover,
	&.active {
		background-color: rgba(0, 0, 0, .05);

		svg{
			fill: #000;
		}
	}

	svg{
		fill: var(--color-grey);
	}

	&.copyIcon{
		padding: 6px;
	}
}

//.item{
//	position: relative;
//	padding: 12px;
//	background: #FFFFFF;
//	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
//	border-radius: 4px;
//	margin-bottom: 4px;
//	display: grid;
//	overflow: hidden;
//	grid-template-columns: min-content 1fr min-content;
//	grid-template-rows: min-content min-content;
//	grid-template-areas: "date title field"
//						 "pin icons controls";
//
//	&:hover{
//		.checkbox,
//		.pin{
//			opacity: 1;
//		}
//	}
//
//	&.isRead{
//		.title,
//		.date{
//			opacity: .4;
//		}
//	}
//
//	&.priority-1{
//		.title{
//			color:#D25245;
//
//			&:hover{
//				color:#D25245;
//			}
//		}
//	}
//
//	&.priority-2{
//		.title{
//			color:#059E79;
//
//			&:hover{
//				color:#059E79;
//			}
//		}
//	}
//
//	&.main{
//		background-color: var(--color-blue2);
//
//		.title,
//		.date{
//			opacity: 1;
//		}
//
//		.checkbox,
//		.pin{
//			opacity: 1;
//		}
//	}
//
//	&.compact{
//		grid-template-areas:
//				"date title controls"
//				"pin title controls";
//
//		.checkbox,
//		.annotate,
//		.controls{
//			display: none;
//		}
//	}
//
//	&.title{
//		grid-template-areas:
//				"date title field"
//				"pin title controls";
//
//		.annotate{
//			display: none;
//		}
//
//		.title{
//			min-height:1px;
//		}
//
//		.date{
//			@include padding(1.5px, 8px, 0, 0);
//		}
//	}
//
//	&.lead{
//
//	}
//
//	&.mini{
//		grid-template-areas:
//				"date title title"
//				"pin title title";
//
//		.title{
//			min-height:1px;
//		}
//	}
//}
//
//.date{
//	color: var(--color-grey);
//	font-weight: 500;
//	font-size: 14px;
//	min-width: 48px;
//	box-sizing: border-box;
//	white-space: nowrap;
//	grid-area: date;
//	@include padding(4px, 8px, 0, 0);
//}
//
//.title{
//	position:relative;
//	font-weight: 500;
//	font-size: 16px;
//	line-height: 1.5;
//	color: #000;
//	cursor:pointer;
//	min-height:48px;
//	grid-area: title;
//	@include padding-right(20px);
//
//	&:hover{
//		color: var(--color-link);
//	}
//}
//
//.leadTitle{
//	margin-top:8px;
//	font-weight: 400;
//	font-size: 16px;
//	line-height: 1.5;
//	color: #656565;
//}
//
//.leadToggle{
//	margin-top:4px;
//	color: var(--color-link);
//	font-size:12px;
//}
//
//.leadIcon{
//	display:inline-block;
//	width:15px;
//	position:relative;
//
//	svg{
//		position:absolute;
//		top:1px;
//		fill: var(--color-link);
//	}
//}
//
//.checkbox{
//	margin: 6px 0 3px 0;
//	display: flex;
//	align-items: flex-start;
//	justify-content: flex-end;
//	grid-area: field;
//	transition: opacity .3s ease;
//
//	@include min1180{
//		opacity: 0;
//	}
//
//	label{
//		margin:0;
//		flex: initial;
//	}
//
//	&.checked{
//		opacity: 1;
//	}
//}
//
//.pin{
//	padding-top:5px;
//	grid-area: pin;
//	opacity: 0;
//	transition: opacity .2s;
//
//	&.pined{
//		opacity: 1;
//		svg{
//			fill: var(--color-blue);
//		}
//	}
//
//	&:hover{
//		svg{
//			fill: var(--color-blue);
//		}
//	}
//}
//
//.annotate{
//	padding-top:5px;
//	display:flex;
//	align-items: center;
//	overflow: hidden;
//	grid-area: icons;
//}
//
//.controls{
//	display: flex;
//	justify-content: flex-end;
//	grid-area: controls;
//	@include margin-right(-5px);
//}
//
//.favorite{
//	width: 24px;
//
//	svg{
//		fill: var(--color-blue);
//	}
//}
//
//.keyword{
//	position:relative;
//	display: block;
//	white-space: nowrap;
//	text-overflow: ellipsis;
//	max-width: 50%;
//	overflow: hidden;
//	line-height:20px;
//	height: 23px;
//	color: var(--color-keywords);
//	@include padding(0, 0, 0, 5px);
//}
//
//.keywordItem{
//	font-weight: 400;
//	font-size: 12px;
//	line-height: 1;
//	text-transform: uppercase;
//	color: var(--color-keywords);
//	display:inline;
//	@include padding(0, 5px, 0, 0);
//
//	&:before{
//		content: '/ ';
//	}
//}
//
//.media{
//	position:relative;
//	font-weight: 400;
//	font-size: 12px;
//	line-height: 25px;
//	color: #656565;
//	white-space:nowrap;
//	@include padding(0, 0, 0, 18px);
//
//	&:after{
//		content: '';
//		display: block;
//		position:absolute;
//		top:3px;
//		@include left(8px);
//		width: 1px;
//		height: 16px;
//		background: var(--color-light2);
//	}
//}
//
//
//.loader{
//	position: absolute;
//	left:0;
//	right:0;
//	top:0;
//	bottom:0;
//	z-index:10;
//	background:#fff;
//}
//
//.icon{
//	width:32px;
//	height:32px;
//	cursor:pointer;
//	border-radius:50%;
//	transition: background-color $transition;
//	@include margin(0, 0, 0, 8px);
//
//	&:hover{
//		background-color: rgba(0,0,0,.05);
//	}
//
//	&.active{
//		background-color: rgba(0,0,0,.05);
//	}
//}