@import "../../assets/styles/utils";

.container{
	//background: var(--color-background);
	padding-bottom: 4px;
}

.warning{
	font-weight: 500;
	font-size: 14px;
	line-height: 1.43;
	color: #000000;
	padding: 10px 12px;
	background: var(--color-warning);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}

.link{
	color: var(--color-link);
	cursor: pointer;

	&:hover{
		color: var(--color-dark);
	}
}