@import "../../assets/styles/utils";


.lenta{
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 100;
	grid-area: lenta;

	&.transition{
		.widget{
			transition: transform .3s;
		}
	}

	&.isOpen{
		z-index: 1000;

		.header{
			display:block;
		}

		.holder{
			display:none;
		}

		.button{
			display:block;
			@include min1180{
				display:flex;
			}
		}

		.grid{
			height:100%;
		}

		.background{
			display: block;
			opacity:1
		}

		.item{
			&:first-child{
				padding:14px 0 0 0;

				@include min1180{
					padding:14px 0 10px 0;
				}
			}
			@include min1180{
				min-height:1px;
			}
		}

		.title{
			display:block;
			white-space: initial;
			max-height:1000px;
		}

		.list{
			@include min1180{
				height: 100%;
			}
		}
	}
}

.background{
	position:fixed;
	z-index:1;
	top:0;
	right:0;
	bottom:0;
	opacity:0;
	background: rgba(0,0,0,.3);
	transition: opacity .3s ease;
	@include left(0);

	&.transition{
		opacity: 1;
	}
}

.widget{
	position: absolute;
	height: 100vh;
	width: 100%;
	background: var(--color-blue2);
	box-shadow: 0px -3px 10px rgba(3, 13, 35, 0.1);
	touch-action: none;
	box-sizing: border-box;
	z-index:111;
	@include left(0);

	@include min1180{
		height:90vh;
	}
}

.wrapper{
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 12px;
	box-sizing: border-box;
	height:100%;
	position: relative;
}

.holder{
	height:24px;
	cursor: grab;

	&:after{
		content: '';
		display:block;
		width: 38px;
		height: 4px;
		border-radius:5px;
		background: var(--color-main);
		position:absolute;
		top:10px;
		left:50%;
		margin-left: -19px;
	}

	@include min1180{
		display:none;
	}
}

.grid{
	height:99999999px;

	@include min1180{
		display: grid;
		grid-template-columns: min-content 1fr min-content;
	}
}

.header{
	display: none;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	box-shadow: rgb(0 0 0 / 15%) 0 1px 4px;
	height: 40px;
	line-height: 40px;
	white-space:nowrap;
	margin: 0 12px;

	@include min1180{
		display:block;
		box-shadow: none;
		font-size: 24px;
		height:42px;
		line-height:42px;
		margin:0;
		@include padding-right(73px);
	}
}

.button{
	display: none;
	position:absolute;
	top: 4px;
	@include right(12px);

	@include min1180{
		display: flex;
		align-items:center;
		position: static;
		height: 42px;
		@include padding-left(48px);
	}
}

.buttonIcon{
	width: 20px;
	height: 20px;
}

.list{
	position: relative;
	height: calc(100% - 40px);
}

.item{
	position:relative;
	display:grid;
	grid-template-columns: min-content 1fr;
	min-height: 46px;
	padding: 14px 0 0 0;

	@include min1180{
		padding: 14px 0 10px 0;
	}

	&:first-child{
		padding:0;

		@include min1180{
			padding: 14px 0 10px 0;
		}
	}
}

.date{
	min-width: 50px;
	font-weight: 400;
	font-size: 16px;
	color: var(--color-grey);
	box-sizing:border-box;
	white-space: nowrap;
	@include padding-right(10px);
}

.title{
	font-weight: 400;
	font-size: 16px;
	color: #000!important;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	max-height: 39px;
	overflow:hidden;

	@include min1180{
		display:block;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&:hover{
		color: var(--color-main);
	}
}


.mini{

}

.miniButton{
	background: var(--color-blue2);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	height:48px;
	position: absolute;
	z-index:10;
	top: 0;
	display: flex;
	min-width: 210px;
	flex-wrap: nowrap;
	align-items: center;
	cursor:pointer;
	padding: 0 12px;
	box-sizing: border-box;
	transition: transform .3s;
	will-change: transform;
	@include right(12px);

	@include min1180{
		@include right(0);
	}
}

.miniText{
	padding: 0 12px 0 0;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #000000;
	flex:1;
}

.miniCount{
	background: var(--color-blue);
	border-radius: 30px;
	color:#fff;
	padding:0 5px;
	height:16px;
	line-height: 16px;
}