@import "../../../assets/styles/utils";

.item{
	background: #FFFFFF;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04), -1px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	display: grid;
	margin-bottom: 4px;
	grid-template-columns: 140px 120px 1fr;

	@include min1180{
		grid-template-columns: 140px 120px 1fr 120px 40px;
	}

	&.value{
		background: var(--color-blue2);
	}
}

.date{
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #656565;
	padding: 4px 12px;
	order:1;
}

.type{
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #656565;
	padding: 4px 12px;
	order:1;
}

.title{
	font-weight: 400;
	font-size: 14px;
	line-height: 1.7;
	color: #000000;
	padding: 4px 12px;
	order:4;
	grid-column: span 3;

	@include min1180{
		order:3;
		grid-column: span 1;
	}
}

.id{
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #656565;
	padding: 4px 12px;
	order:5;
	grid-column: span 3;

	@include min1180{
		order:4;
		grid-column: span 1;
	}
}

.controls{
	padding: 9px 12px;
	order:3;
	display:flex;
	justify-content: flex-end;

	@include min1180{
		order:5;
	}

	label{
		margin:0;
	}
}