@import "../../../assets/styles/utils";


.keywords{
	display:flex;
	width:100%;
	flex-wrap:wrap;
	margin: 14px 0 26px 0;
}

.item{
	position: relative;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.33;
	text-transform: uppercase;
	color: var(--color-link);
	cursor:pointer;
	@include margin(0, 17px, 8px, 0);

	&:after{
		content: '/';
		position: absolute;
		display:block;
		top: -1px;
		@include right(-10px);
	}

	*[data-dir="rtl"] &{
		margin: 0 0 8px 17px!important;

		&:after{
			right: auto!important;
			left: -10px!important;
		}
	}

	&:last-child{
		&:after{
			content: none;
		}
	}
}