@import "../../../assets/styles/utils";

.container{
	padding: 25px 12px;
	margin: 0 auto;
	max-width: 720px;

	@include min768{

	}

	@include min1180{
		padding: 70px 12px;
		max-width: 490px;
	}
}

.text{
	max-width: 560px;
	margin: 0 auto 24px;
}

.title{
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	color: #000;
	margin-bottom: 8px;

	@include min768 {
		text-align: center;
	}
}

.description{
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	color: var(--color-grey);

	a{
		color: var(--color-link);

		&:hover{
			color: var(--color-dark);

			.icon{
				svg{
					fill: var(--color-dark);
				}
			}
		}
	}
}

.icon{
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	//height: 16px;

	svg{
		fill: var(--color-link);
	}
}

.image{
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}