@import "./common.module";

*,*:before,*:after{
  box-sizing: inherit;
}

body{
  font-family: "Golos", sans-serif;
  background: var(--color-background);
  color: #000;
  font-size: 14px;
  min-width: 360px;
  box-sizing: border-box;

  html[dir="rtl"] &{
    font-family: 'PT Sans', sans-serif;
    direction: rtl;
    text-align: right;
  }

  .stie_cn{
    font-family: Verdana, Geneva, sans-serif;
  }
}

a {
  text-decoration: none;
}

.highlight{
  display: inline-block;
  padding: 0 8px;
  background:  var(--color-light);
  border-radius: 4px;
}


:global(.highlight){
  display: inline-block;
  padding: 0 8px;
  background:  var(--color-light);
  border-radius: 4px;
}

:global(.select-arrow-blue){
  svg{
    fill: var(--color-light);
  }
}

:global(.select-remove){
  width: 100%;
  height: 100%;

  svg{
    fill: #B8B8B8;
  }

  &:hover{
    svg{
      fill: #000;
    }
  }
}

:global(.select-remove-delete){
  width: 100%;
  height: 100%;

  svg{
    fill: #fff;
  }

  &:hover{
    svg{
      fill: #fff;
    }
  }
}

:global(.grecaptcha-badge){
  opacity: 0;
  visibility: hidden;
}