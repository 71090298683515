@import "../../assets/styles/utils";

.notFound{

}

.info{
	padding:40px 12px;

	@include min1180{
		padding:120px 12px 50px 12px;
	}
}

.head{
	width:200px;
	height:80px;
	margin-bottom:35px;

	@include min1180{
		margin-bottom:54px;
		width: 520px;
		height: 203px;
	}


	svg{
		fill: #CACBE1!important;
		opacity:0.2;
	}
}

.title{
	font-weight: 600;
	font-size: 32px;
	line-height: 1.2;
	color: #FFFFFF;
	margin-bottom:24px;

	@include min1180{
		font-size: 44px;
		line-height: 0.91;
	}
}

.text{
	font-weight: 400;
	font-size: 16px;
	line-height: 1.62;
	color: #DBDEE9;
	margin-bottom:32px;
}

.link{
	display:inline-block;
	border: 1px solid var(--color-blue);
	border-radius: 50px;
	height:40px;
	line-height:40px;
	padding:0 22px;
	white-space: nowrap;
	overflow:hidden;
	font-size:16px;
	color:#fff;
}