@import "../../../assets/styles/utils";

.label{
	display:flex;
	flex-wrap:wrap;
	max-width:300px;
	padding: 0 12px;
	margin-bottom:16px;

	@include min1180{
		padding: 0 20px;
	}
}

.tag{
	background: var(--color-blue);
	border-radius: 30px;
	margin:0 8px 8px 0;
	padding: 0 8px;
	font-size: 12px;
	height:23px;
	line-height:23px;
	color:#fff;
	cursor:pointer;
}