@import "../../assets/styles/utils";

.content{
	overflow: hidden;
	padding: 0 12px;

	@include min1180{
		padding:12px 6px;
	}
}

.analytics{
	max-width:840px;
	margin: 24px 0;

	@include min1180{
		padding: 0 20px;
	}
}

.head{
	margin: 0 0 12px 0;

	@include min1180{
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}
}

.title{
	font-weight: 400;
	font-size: 18px;
	line-height: 1.44;
	color: #000000;
	margin: 0 0 8px 0;

	@include min1180{
		margin:0;
	}
}

.filter{
	margin: 0 0 24px 0;

	@include min1180 {
		display: flex;
		justify-content: space-between;
		align-items: end;
	}
}

.filterSearch{
	@include min1180{
		width: 250px;
	}

	label{
		margin: 0;
	}
}

.filterRange{
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	gap: 10px;
	min-width: 200px;
}

.filterDate{
	cursor: pointer;
	color: var(--color-grey);

	&.active{
		color: var(--color-link);
	}
}

.graph{
	position: relative;
	margin: 0 0 10px 0;
}

.section{
	margin-bottom: 20px;
}

.sectionTitle{
	margin: 0 0 16px 0;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #000;
}

.gitsectionCount{
	font-size: 16px;
	font-weight: 400;
}

.more{
	display: inline-block;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.43;
	color: var(--color-grey);
	cursor:pointer;

	&:hover{
		color: var(--color-link);
	}
}

.tags{

}

.tagItem{
	display: inline-block;
	vertical-align: middle;
	background: var(--color-blue);
	border-radius: 30px;
	padding:0 8px;
	font-weight: 400;
	font-size: 12px;
	color: #FFFFFF;
	height: 23px;
	line-height: 23px;
	margin: 0 8px 8px 0;
	cursor: pointer;
	transition: background .2s;

	&:hover{
		background: var(--color-link);
	}
}